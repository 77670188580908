import React from "react";
import { useState, useEffect, useRef } from "react";
import { formatDistanceToNow } from "date-fns";
import axios from "axios";

const Comment = ({ id }) => {
  const [ar, setAr] = useState(true);
  const [data, setData] = useState([]);
  const [commenti, setCommenti] = useState([]);
  const dataRetriever = async () => {
    await axios
      .get(`https://tmull-be.vercel.app/comment/${id}`)
      .then((response) => {
        console.log(response?.data);
        setData(response?.data);
      });
  };
  useEffect(() => {
    console.log(localStorage.getItem("user"));
    document.title = "Tmull ・ Мэдээ";

    dataRetriever();
  }, []);
  const dataRetriever2 = async () => {
    if (localStorage.getItem("user")) {
      await axios({
        url: `https://tmull-be.vercel.app/Comment`,
        method: "POST",
        data: {
          content: commenti,
          user: localStorage.getItem("user"),
          postId: id,
        },
      }).then((response) => {
        document.getElementById("F").value = "";
        dataRetriever();
      });
    } else {
      window.location.href = "/nevtreh";
    }
  };
  const like = async (cId) => {
    if (localStorage.getItem("user")) {
      await axios({
        url: `https://tmull-be.vercel.app/Comments/${cId}/like`,
        method: "POST",
        data: {
          userId: localStorage.getItem("user"),
        },
      }).then((response) => {
        dataRetriever();
      });
    } else {
      window.location.href = "/";
    }
  };
  const dlike = async (cId) => {
    if (localStorage.getItem("user")) {
      await axios({
        url: `https://tmull-be.vercel.app/Comments/${cId}/dlike`,
        method: "POST",
        data: {
          userId: localStorage.getItem("user"),
        },
      }).then((response) => {
        dataRetriever();
      });
    } else {
      window.location.href = "/";
    }
  };
  return (
    <div>
      <div>
        <div className="mt-8 flex justify-center flex-col w-full items-center">
          <div className="flex flex-row items-center justify-center mb-4">
            <div className="text-2xl lg:text-4xl">Сэтгэгдлийн хэсэг</div>
          </div>
          <div className="gap-4 flex flex-col lg:w-[60%] w-[95%]">
            <div className="w-full">
              <div className="relative div ">
                {/* <input
                type="text"
                placeholder=" "
                id="Feedback"
                className="rounded-md shadow-sm border-2 sm:text-sm outline-none focus:ring-2 ring-black"
              /> */}
                <textarea
                  id="F"
                  rows="4"
                  placeholder=" "
                  onChange={(e) => setCommenti(e.target.value)}
                  className="dark:bg-neutral-900 rounded-md shadow-sm border-2 text-sm outline-none ring-0 dark:border-none focus:ring-2 ring-black dark:ring-neutral-600 input w-full max-h-44 lg:max-h-72 min-h-16"
                ></textarea>
                <label
                  for="Feedback"
                  className="bg-white dark:bg-neutral-900 px-1 rounded-md text-sm md:text-base"
                >
                  Сэтгэгдэл
                </label>
              </div>
              <div className="mt-3 w-full flex justify-end">
                <button
                  onClick={dataRetriever2}
                  class="transition ease-in-out duration-300 px-4 bg-black rounded-lg text-white py-2 dark:bg-neutral-300 hover:scale-110 hover:bg-gray-950 dark:text-black"
                >
                  Илгээх
                </button>
              </div>
            </div>
            <div className="w-full gap-12 flex flex-col overflow-y-auto">
              {data.map((el) => {
                return (
                  <div>
                    <div className="flex flex-row items-center gap-2">
                      <div>
                        <svg
                          width="35"
                          height="35"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M24.5661 23.185C26.705 20.7266 28 17.5145 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 17.5144 1.29491 20.7263 3.43364 23.1847C4.70028 20.1035 6.66276 18.327 8.63318 17.33C10.8511 16.2077 13.0011 16.1112 13.9998 16.1112C14.9986 16.1112 17.1485 16.2077 19.3664 17.33C21.3369 18.3271 23.2995 20.1036 24.5661 23.185ZM23.0061 24.7191C21.9396 21.5833 20.1542 19.9701 18.4634 19.1145C16.6556 18.1998 14.8735 18.1112 13.9998 18.1112C13.1261 18.1112 11.344 18.1998 9.53617 19.1145C7.84549 19.97 6.06008 21.5832 4.9936 24.7189C7.42803 26.7665 10.57 28 14 28C17.4299 28 20.5717 26.7666 23.0061 24.7191ZM14.0001 12.7778C15.9024 12.7778 17.4446 11.2357 17.4446 9.3334C17.4446 7.43109 15.9024 5.88896 14.0001 5.88896C12.0978 5.88896 10.5557 7.43109 10.5557 9.3334C10.5557 11.2357 12.0978 12.7778 14.0001 12.7778ZM14.0001 14.7778C17.007 14.7778 19.4446 12.3403 19.4446 9.3334C19.4446 6.32652 17.007 3.88896 14.0001 3.88896C10.9932 3.88896 8.55568 6.32652 8.55568 9.3334C8.55568 12.3403 10.9932 14.7778 14.0001 14.7778Z"
                            fill="black"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-col">
                        {" "}
                        <div>{el.author.username}</div>
                        <div className="text-xs text-zinc-700 dark:text-zinc-300">
                          {formatDistanceToNow(new Date(el.cAt), {
                            addSuffix: true,
                          })
                            .replace(
                              "less than a minute ago",
                              "минут хүрэхгүй хугацааны өмнө"
                            )
                            .replace("about ", "")
                            .replace("hours", "цагийн")
                            .replace("hour", "цагийн")
                            .replace("minutes", "минутын")
                            .replace("minute", "минутын")
                            .replace("ago", "өмнө")}
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">{el.content}</div>
                    <div className="flex flex-row mt-2 w-full border-t-2 pt-2 text-sm ">
                      <div>Та уг сэтгэгдэлтэй санаа нийлж байна уу?</div>
                      <div className="flex items-center flex-row justify-center">
                        <svg
                          onClick={() => like(el._id)}
                          style={{
                            fill: el.like.includes(localStorage.getItem("user"))
                              ? "green"
                              : "none",
                          }}
                          className="h-4 lg:h-5 px-2 hover:fill-green-700 cursor-pointer overflow-visible"
                          viewBox="0 0 311 282"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M184.071 113.23L187.117 106.234M184.071 113.23L211.721 41.7504C211.721 26.8784 203.066 12.7324 188.586 6.42538C179.926 2.65638 169.844 6.61939 166.077 15.2844L166.076 15.2874L138.016 79.7244C134.114 88.6984 127.924 96.4704 120.086 102.275C120.089 103.427 120.093 104.937 120.096 106.76C120.103 111.154 120.11 117.377 120.116 124.815C120.129 139.692 120.139 159.434 120.148 179.146C120.156 198.857 120.162 218.538 120.166 233.291C120.167 240.668 120.169 246.813 120.17 251.113V253.858C126.559 255.477 132.687 258.014 138.361 261.396C147.419 266.793 157.767 269.641 168.311 269.641H265.191C279.832 269.641 292.182 258.708 293.957 244.168L293.958 244.165L305.387 150.07L305.387 150.067C307.756 130.474 292.478 113.23 272.751 113.23L184.071 113.23ZM70.2865 275.87L70.2775 275.87L70.2685 275.87L15.1245 276.67C15.1205 276.67 15.1165 276.67 15.1125 276.67C13.7905 276.681 12.4785 276.431 11.2525 275.936C10.0255 275.44 8.90753 274.708 7.96353 273.781C6.06653 271.902 5.00052 269.345 5.00052 266.67V102.649C5.00052 97.1314 9.48252 92.6494 15.0005 92.6494H70.1705C75.6895 92.6494 80.1705 97.1314 80.1705 102.649L80.1705 265.87C80.1705 271.353 75.7635 275.81 70.2865 275.87Z"
                            className="stroke-black dark:stroke-neutral-400"
                            stroke-width="20"
                          />
                        </svg>
                        <div>{el.like.length}</div>
                      </div>
                      <div className="flex items-center flex-row justify-center">
                        <svg
                          onClick={() => dlike(el._id)}
                          style={{
                            fill: el.dlike.includes(
                              localStorage.getItem("user")
                            )
                              ? "red"
                              : "none",
                          }}
                          className="h-4 lg:h-5 px-2 hover:fill-red-700 cursor-pointer overflow-visible"
                          viewBox="0 0 311 282"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M126.56 168.44L123.514 175.436M126.56 168.44L98.9099 239.92C98.9099 254.792 107.565 268.938 122.045 275.245C130.705 279.014 140.787 275.051 144.554 266.386L144.555 266.383L172.615 201.946C176.517 192.972 182.707 185.2 190.545 179.395C190.542 178.243 190.538 176.733 190.535 174.91C190.528 170.516 190.521 164.293 190.515 156.855C190.502 141.978 190.492 122.236 190.483 102.524C190.475 82.813 190.469 63.132 190.465 48.379C190.464 41.002 190.462 34.8578 190.461 30.5574V27.8124C184.072 26.1934 177.944 23.6564 172.27 20.2746C163.212 14.8777 152.864 12.0289 142.32 12.0296H45.4396C30.7982 12.0296 18.4483 22.9621 16.6732 37.5025L16.6728 37.5051L5.24346 131.6L5.24306 131.603C2.87456 151.196 18.1525 168.44 37.8796 168.44H126.56ZM240.344 5.80033L240.353 5.80023L240.362 5.80013L295.506 5.00042C295.51 5.00042 295.514 5.00042 295.518 5.00032C296.84 4.98972 298.152 5.23912 299.378 5.73442C300.605 6.23012 301.723 6.96232 302.667 7.88932C304.564 9.76822 305.63 12.3258 305.63 15.0006V179.021C305.63 184.539 301.148 189.021 295.63 189.021H240.46C234.941 189.021 230.46 184.539 230.46 179.021V15.8006C230.46 10.3177 234.867 5.86033 240.344 5.80033Z"
                            className="stroke-black dark:stroke-neutral-400"
                            stroke-width="20"
                          />
                        </svg>
                        <div>{el.dlike.length}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Comment;
