import React from "react";
import { useState, useEffect } from "react";
import gh from "../assets/godhand.jpg";
import nova from "../assets/nova.png";
import Goethe from "../assets/Goethe.png";
import jlpt from "../assets/jlpt.png";
import hsk from "../assets/hsk.jpg";
import availableb from "../assets/bolomjtoib.svg";
import unavailableb from "../assets/bolomjguib.svg";
import sbdbg from "../assets/sbd.png";
import sat from "../assets/sat.png";
import ielts from "../assets/ielts.png";
import topik from "../assets/topik.png";
import ush from "../assets/ush.svg";
import esh from "../assets/esh.svg";
import oge from "../assets/oge.jpg";
import ege from "../assets/ege.png";
import tmuleh from "../assets/tmuleh.png";
import axios from "axios";
import { formatDistanceToNow } from "date-fns";
const Countdown = ({ targetDate }) => {
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    const calculateDaysLeft = () => {
      const now = new Date();
      const target = new Date(targetDate);
      const difference = target - now; // Difference in milliseconds
      const days = Math.ceil(difference / (1000 * 60 * 60 * 24)); // Convert to days
      setDaysLeft(days);
    };

    calculateDaysLeft(); // Initial calculation

    const interval = setInterval(() => {
      calculateDaysLeft(); // Update daily
    }, 1000 * 60 * 60 * 24); // Recalculate every day

    return () => clearInterval(interval); // Cleanup on unmount
  }, [targetDate]);

  return <div>{daysLeft > 0 ? daysLeft : 0} хоног</div>;
};
export const Home = () => {
  const [qw, setQw] = useState(true);
  const [pos, setPos] = useState(0);
  const [acad, setAcad] = useState([]);
  const [news, setNews] = useState([]);
  const [prof, setProf] = useState([]);
  const [event, setEvent] = useState([]);
  const [school, setSchool] = useState([]);

  const [formattedDate, setFormattedDate] = useState("");

  const mergejil = [
    {
      id: 1,
      ner: "./career/careers_people_man_accountant_finance_skin3.svg",
      ajil: "нягтлан бодогч",
    },
    {
      id: 2,
      ner: "./career/careers_people_man_dentist_skin3.svg",
      ajil: "шүдний эмч",
    },
    {
      id: 3,
      ner: "./career/careers_people_man_lab_tech_laboratory_technician_skin3.svg",
      ajil: "лабораторийн техникч",
    },
    {
      id: 4,
      ner: "./career/careers_people_man_marketing_manager_skin3.svg",
      ajil: "маркетинг",
    },
    {
      id: 5,
      ner: "./career/careers_people_man_medical_nurse_skin3.svg",
      ajil: "сувилагч",
    },
    {
      id: 6,
      ner: "./career/careers_people_man_paramedic_skin3.svg",
      ajil: "түргэн тусламж",
    },
    {
      id: 7,
      ner: "./career/careers_people_man_sales_finance_manager_skin3.svg",
      ajil: "Санхүүгийн менежер",
    },
    {
      id: 8,
      ner: "./career/careers_people_man_senior_librarian_skin3.svg",
      ajil: "номын санч",
    },
    {
      id: 9,
      ner: "./career/careers_people_man_software_developer_skin3.svg",
      ajil: "хөгжүүлэгч",
    },
    {
      id: 10,
      ner: "./career/careers_people_man_teacher_professor_skin3.svg",
      ajil: "багш",
    },
    {
      id: 11,
      ner: "./career/careers_people_woman_art_director_skin3.svg",
      ajil: "Урлан бүтээгч",
    },
    {
      id: 12,
      ner: "./career/careers_people_woman_engineer_architect_skin3.svg",
      ajil: "архитекторч",
    },
    {
      id: 13,
      ner: "./career/careers_people_woman_executive_director_manager_skin3.svg",
      ajil: "менежер",
    },
    {
      id: 14,
      ner: "./career/careers_people_woman_hr_human_resources_manager_skin3.svg",
      ajil: "Хүний нөөцийн менежер",
    },
    {
      id: 15,
      ner: "./career/careers_people_woman_lawyer_skin3.svg",
      ajil: "хуульч",
    },
    {
      id: 16,
      ner: "./career/careers_people_woman_medical_doctor_skin3.svg",
      ajil: "эмч",
    },
    {
      id: 17,
      ner: "./career/careers_people_woman_professor_teacher_skin3.svg",
      ajil: "профессор",
    },
    {
      id: 18,
      ner: "./career/careers_people_woman_public_relations_specialist_skin3.svg",
      ajil: "олон нийтийн харилцааны мэргэжилтэн",
    },
    {
      id: 19,
      ner: "./career/careers_people_woman_senior_psychologist_skin3.svg",
      ajil: "сэтгэл зүйч",
    },
    {
      id: 20,
      ner: "./career/careers_people_woman_vet_veterinarian_skin3.svg",
      ajil: "малын эмч",
    },
    {
      id: 21,
      ner: "./career/und.svg",
      ajil: "шийдэгдээгүй",
    },
  ];
  const colors = [
    "#994C8E",
    "#E09626",
    "#4BAB4D",
    "#5A9FDE",
    "#556EB0",
    "#6FAEA9",
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  const adviceList = [
    "Тэнцсэн сургуулиудаасаа хангалттай тэтгэлэг авч, гартаа 10000-15000$ бэлэн байлгаарай.",
    "IELTS-ийн 4 хэсгүүд (Сонсох, Унших, Бичих, Ярих) болон тэдгээрийн форматын талаар мэдэж аваарай.",
    "Хүссэн тэтгэлэгт хамрагдахдаа шаардагдах оноогоо тодорхойлж, бэлтгэлээ түүндээ төвлөрүүлээрэй.",
    "Хичээлийн дүн нь олон тэтгэлэгт хамрагдах гол шалгуур болдог тул өндөр оноо авахад сайн анхаараарай.",
    "Та өөрийн манлайлал, багаар ажиллах чадвараа харуулсан үйл ажиллагаануудад оролцож, өөрийгөө харуулаарай.",
    "Тэтгэлэгт хамрагдах ярилцлагын нийтлэг асуултуудад дадлага хийж, өөртөө итгэх итгэлийг нэмэгдүүлж, харилцааны ур чадвараа сайжруулахын тулд бусадтай ярилцаарай.",
    "Өргөдөл гаргах эцсийн хугацаа, шаардлагатай бичиг баримт, илгээх огноог төлөвлөгч эсвэл дижитал хуанли ашиглан зохион байгуулалттай байгаарай.",
    "Өөрт тохирсон мэргэжлийг олохын тулд манай мэргэжлүүдийн буланг сонирхож, цааш сайн судлаад үзээрэй!",
    "Өргөдөл шалгагч таны хичээлээс гадуурх үйл ажиллагааны эхний 3-5-ыг анхааралтай хардаг учраас өөрт тань хамгийн чухал зүйлсээ эхэнд бичээрэй. (нийт 10 slot байгаа жүү.)",
    "Таныг бусад өргөдөл гаргагчдаас ялгарах сайн дурын ажил, дадлага, тусгай төсөл гэх мэт өвөрмөц туршлагуудыг онцлон тэмдэглээрэй.",
    "Хэрвээ та 12 анги бол танд зүгээр суух цаг байхгүй шүү. Их сургуулиудын судалгаагаа сайн хийж, хувийн бэлтгэлээ хангаж, эсээнүүдээ чанартай бичээрэй.",
    "Өөрийнхөө ахиц дэвшилд дүн шинжилгээ хийж, илүү их дадлага хийх шаардлагатай газруудыг тодорхойлоорой.",
    "Татгалзсан хариу авсан ч гэсэн цоглог, зорилгодоо тууштай байгаарай. Өргөдлөө сайжруулахын тулд туршлагаа ихэсгээрэй.",
    "Өнөөдөртөө сайн амраад авбал яаж байна? Өөрийгөө хайрлаад, хийхийг хүссэн бүхнээ хийдэг өдөр шүү! Өөртөө сайн цаг гаргаарай.",
    "Төлөвлөгөөгөө сайн гаргаж, түүнийгээ сайн биелүүлээрэй!",
    "Хаана сурахаа сураагүй байгаа бол битгий санаа зовоорой! Бүгдэнг нь л туршаад үзэх хэрэгтэй!",
    "Эсээн дээрээ өөрийгөө хэрхэн сайн харуулахыг мэдэхгүй байвал өдрийн тэмдэглэл хөтлөөд үзээрэй.",
    "Таны сонгосон мэргэжлийг хамгийн сайн заадаг их сургуулиудыг улс хамаарахгүй сонгоорой.",
    "Өнөөдөр бодсон төлөвлөсөн ажлынхаа ард амжилттай гараад инээгээрэй!",
    "Сонгосон мэргэжил тань ирээдүйд эрэлттэй эсэхийг сайн судлаарай.",
    "Америкийн их сургуулиуд руу өргөдлөө илгээхийг төлөвлөж байвал Common App дээр ажиллаж, сураарай.",
    "SAT шалгалтад бэлдэхийг хүсэж байвал Khan Academy дээр дасгал ажлуудыг сайн ажиллаж, Bluebook-ийн mock тестүүдийг өгөөд үзэх хэрэгтэй.",
    "Collegeboard сайт дээр сайн ажиллаж, зорилгуудаа бататгаарай.",
    "Стандартчлагдсан шалгалтууддаа өдөр бүр тодорхой хугацаанд бие даан бэлдэж байгаарай!",
    "Хамтарч бэлдэх ханьтай болвол таны идэвхтэй байх түвшин ихэснэ шүү!",
    "Чамайг аз жаргалтай болгодог зүйлсийг илүү их хийгээрэй, юу мэдэрч байгаагаа бусдад хэлж байгаарай, аливааг эхлүүлэхдээ төгс эхлүүлэх гэж бүү хүлээ, шууд хийж эхлээрэй, бас аль болох өөрийгөө эрхлүүлээрэй, яагаад гэвэл амьдрал маш хурдан бас завгүй.",
    "Хэлний түвшингээ сайн нэмэгдүүлж, өөрийн хүрээллээ тэр хэлээр дүүргэж, сайн дадлага хийгээрэй.",
    "Дэлхийн амжилттай явж байгаа хүмүүсийн 88% нь их дээд сургуульд сурж байсан тул та ч гэсэн сураад амжилттай болвол ямар вэ?",
    "Сурахаар болсон улсын онцлог болон уламжлалыг эртнээс судлаж, сурах нь чухал шүү!",
    "Өргөдлөө хэзээ хаагдахыг сайн судлаж, цагаасаа хоцрохгүй байх нь чухал шүү!",
    "Recommendation Letters-ийг таныг хамгийн сайн мэддэг багшуудаараа бичүүлээрэй.",
    "Зориж буй их сургуулийнхаа веб хуудсыг тултал нь ухаж уншаарай.",
    "Чамайг урамшуулах, дэмжих найз нөхөдтэй болох нь чухал шүү.",
    "Хичээлээс гадна өөрийгөө тайвшруулах хоббигоо хөгжүүлээрэй.",
    "Өөрийн суралцах хэв маягт тохирсон стратеги боловсруулж, хэрэгжүүлээрэй.",
    "Хийх ажлуудаа жагсааж, хугацаанд нь хийхэд анхаарал хандуулаарай.",
    "Бүх зүйл амархан бүтэхгүй ч, тэвчээртэй байж, зорилгодоо хүрээрэй.",
    "Хичээлд сайн оролцож, багш нараасаа зөвлөгөө авахад цаг гаргаарай.",
    "Хичээлийнхээ хажуугаар эрүүл хооллох, дасгал хөдөлгөөн хийхээ мартаж болохгүй шүү.",
    "Өөрийн санхүүгээ зөв зохицуулж хуримтлал үүсгээрэй, хэрэг болно шүү.",
    "Суралцах болон амралтын цагаа зөв зохицуулаарай.",
    "Эссе бичихдээ өөрийн түүх, хүсэл тэмүүллийг тодорхой гаргаарай.",
    "CV дээрээ амжилт, туршлагаа тодорхой бичихэд анхаараарай.",
    "Зорилгодоо хүрэхийн тулд өөрийгөө урамшуулж, шаргуу хөдөлмөрлөөрэй.",
    "Мэргэжлийн салбарт туршлага хуримтлуулах дадлагад хамрагдаж, CV-гээ баяжуул.",
    "Нийгэмд эерэг нөлөө үзүүлэх сайн дурын ажилд идэвхтэй оролцсоноор эсээ тань баяжна.",
    "Хичээлээс гадуур шинэлэг төслүүдэд оролцож, шинэ зүйлс суралцаж дуртай зүйлээ олоорой.",
    "Дурлаж хийх зүйл чинь хаа нэгтээ байгаа, олоод ав.",
  ];
  useEffect(() => {
    // Retrieve the saved index from localStorage or start from the beginning
    const savedIndex = localStorage.getItem("adviceIndex");
    const today = new Date().toLocaleDateString();

    if (savedIndex) {
      const { index, date } = JSON.parse(savedIndex);
      if (date === today) {
        setCurrentIndex(index);
      } else {
        showNextAdvice(index);
      }
    } else {
      showNextAdvice(0);
    }
  }, []);

  const showNextAdvice = (index) => {
    const nextIndex = (index + 1) % adviceList.length;
    setCurrentIndex(nextIndex);

    // Save the new index and date in localStorage
    const today = new Date().toLocaleDateString();
    localStorage.setItem(
      "adviceIndex",
      JSON.stringify({ index: nextIndex, date: today })
    );
  };
  useEffect(() => {
    document.title = "Tmull ・ Эхлэл";
    const today = new Date();
    setFormattedDate(today.toLocaleDateString());
    const academy = async () => {
      await axios.get(`https://tmull-be.vercel.app/news`).then((response) => {
        console.log(response?.data);
        setNews(response?.data);
      });
      await axios.get(`https://tmull-be.vercel.app/acad`).then((response) => {
        console.log(response?.data);
        setAcad(response?.data);
      });
      await axios.get(`https://tmull-be.vercel.app/event`).then((response) => {
        console.log(response?.data);
        setEvent(response?.data);
      });
      await axios.get(`https://tmull-be.vercel.app/Sch`).then((response) => {
        console.log(response?.data);
        setSchool(response?.data);
      });
      await axios.get(`https://tmull-be.vercel.app/prof`).then((response) => {
        console.log(response?.data);
        setProf(response?.data);
      });
    };
    academy();
  }, []);
  const exam = [{ name: "IELTS", date: [{ year: "2024", date: [{}] }] }];
  const right = () => {
    document.getElementById("content").scrollBy(600, 0);
  };
  const left = () => {
    document.getElementById("content").scrollBy(-600, 0);
  };
  const rightt = () => {
    if (pos < 9) {
      document.getElementById("contentt").scrollBy(400, 0);
      setQw(!qw);
      setPos(pos + 1);
    }
  };
  const leftt = () => {
    if (pos !== 0) {
      document.getElementById("contentt").scrollBy(-400, 0);
      setQw(!qw);
      setPos(pos - 1);
    }
  };
  return (
    <div className="mt-24 lg:mt-28">
      <div className="w-full bg-[#98B851] bg-repeat about rounded-3xl flex flex-col justify-center items-center py-3 sm:py-4 md:py-8 lg:py-12 xl:py-16 2xl:py-20 text-center transition-all delay-150 duration-500 ease-in-out font-semibold">
        <div className="px-2 bg-[#FFFFFF] text-[#4BAB4D] rounded md:rounded-md text-xs sm:md:text-lg xl:text-xl 2xl:text-2xl transition-all delay-150 duration-500 ease-in-out">
          #4 Чанартай боловсролыг дэмжих
        </div>
        <div className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl transition-all delay-150 duration-500 ease-in-out">
          <div className="text-[#FFFFFF]">Илүү дээшээ тэмүүлж</div>
          <div className="text-[#037F00]">Ирээдүйгээ бүтээцгээе!</div>
        </div>
        <div className="font-medium md:text-lg xl:text-xl 2xl:text-2xl text-[#FFFFFF] lg:mt-2 transition-all delay-150 duration-500 ease-in-out">
          Боломж бүгдэд
        </div>
        <div className="w-[90%] sm:w-[85%] md:w-[80%] lg:w-[75%] xl:w-[70%] 2xl:w-[65%] text-xs sm:text-sm md:text-md xl:text-lg text-[#FFFFFF] text-justify lg:mt-4 font-medium transition-all delay-150 duration-500 ease-in-out">
          “Tmull” веб хуудасны гол зорилго нь тогтвортой хөгжлийн 4, 8, 9, 10,
          17-р зорилт болох “Чанартай боловсролыг дэмжих”, “Эдийн засгийн өсөлт,
          баталгаат ажлын байрыг бий болгох”, “Инновац болон дэд бүтцийн
          нэмэгдүүлэх”, “Тэгш бус байдлыг бууруулах”, “Хөгжлийн төлөөх
          түншлэлийг бэхжүүлэх“ гэх 5-н зорилтуудыг хэрэгжүүлэн их дээд
          сургуулиудад сурах хүсэл тэмүүлэлтэй болон ирээдүйн мэргэжлээ
          гүнзгийрүүлэн судлах хүсэлтэй сурагч, оюутан, залуусыг хэрэгтэй
          мэдлэг, мэдээллээр хангаж, туслахад оршино. Үүгээр дамжуулан
          боловсролын тэгш бус байдлыг бууруулах, мэдээллийн хүртээмжийг
          нэмэгдүүлэх тухай олон нийтэд нөлөөлөх зорилготой.
        </div>
      </div>
      <div className="my-6 transition-all ease-in-out duration-300 delay-0 transform">
        <div className="w-full flex justify-center items-center lg:py-4 py-3 overflow-hidden relative">
          <div className="w-full flex flex-col justify-between items-center h-full absolute opacity-75">
            <div class="relative flex flex-row z-0">
              <div class="animate-marquee whitespace-nowrap flex flex-row">
                <span className="xl:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="lg:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
              </div>

              <div class="absolute top-0 animate-marquee2 whitespace-nowrap flex flex-row">
                <span className="xl:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="lg:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
              </div>
            </div>
            <div class="relative flex flex-row">
              <div class="animate-marquee whitespace-nowrap flex flex-row">
                <span className="lg:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="lg:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
              </div>

              <div class="absolute top-0 animate-marquee2 whitespace-nowrap flex flex-row">
                <span className="lg:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="lg:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span className="md:flex hidden">
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
                <span>
                  <img src={sbdbg} alt="" />
                </span>
              </div>
            </div>
          </div>
          <div className="h-full animate-marquee3 whitespace-nowrap dark:text-white justify-center items-center font-semibold z-10  my-4 md:my-6 lg:my-8 xl:my-10 2xl:my-14 w-full text-center flex flex-row text-md md:text-lg lg:text-xl 2xl:text-2xl transition-all ease-in-out duration-300 delay-0 transform">
            {adviceList[currentIndex]}
          </div>
        </div>
        <div className="font-semibold w-full text-sm lg:text-xl md:text-md transition-all ease-in-out duration-300 delay-0 transform">
          <div className="right-0 absolute dark:text-white">
            Энэ өдрийн зөвлөгөө
          </div>
        </div>
      </div>
      <div className="text-center flex justify-center items-center flex-col lg:flex-row dark:text-white">
        <div>
          <div className="text-[#4BAB4D] text-7xl capitalize font-black">
            Бидний нэг болох
          </div>
          <div className=" font-light">Хамтдаа илүү дээшээ тэмүүлцгээе</div>
          <a
            href="https://forms.gle/E28fRpaEunKLHxtHA"
            target="_blank"
            className="mt-4 hover:text-[#4BAB4D] cursor-pointer"
          >
            👉🏻 ЯГ ОДОО БҮРТГҮҮЛЭХ 👈🏻
          </a>
        </div>
        <div>
          <img className="h-80" src={tmuleh} alt="" />
        </div>
      </div>
      <div className="flex flex-col items-start justify-center my-16 ">
        <div className="font-semibold flex flex-row items-center justify-between mb-4 w-full relative">
          <div className="font-semibold flex flex-row items-center justify-center">
            <div className="text-sm md:text-lg rounded-md bg-[#4BAB4D] text-white px-1 mr-1">
              ГАЛ
            </div>
            <a
              href="/niitleluud"
              className="cursor-pointer text-lg md:text-xl lg:text-2xl transition-all ease-in-out duration-300 delay-0 transform dark:text-white"
            >
              Мэдээ мэдээлэл
            </a>
          </div>
          <a
            href="/niitleluud"
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0 font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div className="flex flex-row gap-4 overflow-x-auto w-full dark:text-white">
          {news.length > 0 ? (
            <>
              {news.map((el) => (
                <a href={"/niitleluud/" + el.pl}>
                  <div className="w-80 h-80 lg:w-96 lg:h-96 rounded-lg relative overflow-hidden group/item cursor-pointer">
                    <img
                      className="w-80 h-80 lg:w-96 lg:h-96 object-cover absolute"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/News%2F" +
                        el.img
                      }
                      alt=""
                    />
                    <div className="absolute flex flex-row items-center justify-center gap-1 backdrop-blur-sm rounded px-1 m-2 bg-white/50 dark:bg-black/50">
                      <svg
                        className="h-5 dark:fill-white fill-black"
                        viewBox="0 0 512 512"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                        />
                      </svg>
                      {el.s}
                    </div>
                    <div className="absolute backdrop-blur-sm rounded px-1 m-2 right-0 bg-white/50 dark:bg-black/50">
                      {el.rt}
                    </div>
                    <div className="from-[#000000] to-[#00000000] w-full bg-gradient-to-t h-[90%] group-hover/item:h-full group-hover/item:opacity-100 opacity-90 absolute bottom-0 transition-all ease-in-out duration-500 delay-0 transform"></div>
                    <div className="absolute bottom-0 p-2 w-full text-white">
                      <div className="font-bold text-xl md:text-2xl">
                        {el.ti}
                      </div>
                      <div className="overflow-hidden font-normal text-[#cecece] truncate">
                        {el.di}
                      </div>
                      <div className="font-normal text-[#7c7c7c] truncate group-hover/item:h-5 h-0 lg:group-hover/item:h-6 group-hover/item:opacity-100 opacity-0 transition-all ease-in-out duration-300 delay-0 transform">
                        {formatDistanceToNow(new Date(el.cAt), {
                          addSuffix: true,
                        })
                          .replace(
                            "less than a minute ago",
                            "минут хүрэхгүй хугацааны өмнө"
                          )
                          .replace("about ", "")
                          .replace("days", "өдрийн")
                          .replace("hours", "цагийн")
                          .replace("hour", "цагийн")
                          .replace("minutes", "минутын")
                          .replace("minute", "минутын")
                          .replace("months", "сарын")
                          .replace("month", "сарын")
                          .replace("ago", "өмнө")}{" "}
                        | {el.au.map((el) => el).join(", ")}
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </>
          ) : (
            <div className="flex flex-row gap-4 w-full">
              <div className="rounded-lg bg-black/25 dark:bg-white/25">
                <div className="w-80 h-80 lg:w-96 lg:h-96 animate-pulse  relative overflow-hidden group/item cursor-pointer">
                  <div className="absolute flex flex-row gap-1 backdrop-blur-sm rounded px-1 m-2 bg-white/50 dark:bg-black/50 w-12">
                    <svg
                      className="h-5 dark:fill-white fill-black"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                      />
                    </svg>
                  </div>
                  <div className="absolute backdrop-blur-sm rounded px-1 m-2 right-0 bg-white/50 dark:bg-black/50 h-4 w-12"></div>
                  <div className="absolute bottom-0 p-2 w-full flex flex-col gap-2 text-white">
                    <div class="h-8 bg-white/25 rounded w-[40%]"></div>
                    <div class="h-8 bg-white/25 rounded w-full"></div>
                    <div class="h-4 bg-white/25 rounded w-full mt-1"></div>
                  </div>
                </div>
              </div>
              <div className="rounded-lg bg-black/25 dark:bg-white/25">
                <div className="w-80 h-80 lg:w-96 lg:h-96 animate-pulse  relative overflow-hidden group/item cursor-pointer">
                  <div className="absolute flex flex-row gap-1 backdrop-blur-sm rounded px-1 m-2 bg-white/50 dark:bg-black/50 w-12">
                    <svg
                      className="h-5 dark:fill-white fill-black"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                      />
                    </svg>
                  </div>
                  <div className="absolute backdrop-blur-sm rounded px-1 m-2 right-0 bg-white/50 dark:bg-black/50 h-4 w-12"></div>
                  <div className="absolute bottom-0 p-2 w-full flex flex-col gap-2 text-white">
                    <div class="h-8 bg-white/25 rounded w-[40%]"></div>
                    <div class="h-8 bg-white/25 rounded w-full"></div>
                    <div class="h-4 bg-white/25 rounded w-full mt-1"></div>
                  </div>
                </div>
              </div>
              <div className="rounded-lg bg-black/25 dark:bg-white/25">
                <div className="w-80 h-80 lg:w-96 lg:h-96 animate-pulse  relative overflow-hidden group/item cursor-pointer">
                  <div className="absolute flex flex-row gap-1 backdrop-blur-sm rounded px-1 m-2 bg-white/50 dark:bg-black/50 w-12">
                    <svg
                      className="h-5 dark:fill-white fill-black"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                      />
                    </svg>
                  </div>
                  <div className="absolute backdrop-blur-sm rounded px-1 m-2 right-0 bg-white/50 dark:bg-black/50 h-4 w-12"></div>
                  <div className="absolute bottom-0 p-2 w-full flex flex-col gap-2 text-white">
                    <div class="h-8 bg-white/25 rounded w-[40%]"></div>
                    <div class="h-8 bg-white/25 rounded w-full"></div>
                    <div class="h-4 bg-white/25 rounded w-full mt-1"></div>
                  </div>
                </div>
              </div>
              <div className="rounded-lg bg-black/25 dark:bg-white/25">
                <div className="w-80 h-80 lg:w-96 lg:h-96 animate-pulse  relative overflow-hidden group/item cursor-pointer">
                  <div className="absolute flex flex-row gap-1 backdrop-blur-sm rounded px-1 m-2 bg-white/50 dark:bg-black/50 w-12">
                    <svg
                      className="h-5 dark:fill-white fill-black"
                      viewBox="0 0 512 512"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M256 410.667C306.074 410.667 346.667 370.074 346.667 320.001C346.667 269.927 306.074 229.334 256 229.334C205.926 229.334 165.333 269.927 165.333 320.001C165.333 370.074 205.926 410.667 256 410.667Z" />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M480.64 276.693L448.341 244.373C342.101 138.155 169.899 138.155 63.6588 244.373L31.3601 276.693C29.8753 278.178 28.6975 279.941 27.8939 281.881C27.0904 283.821 26.6768 285.9 26.6768 288C26.6768 290.1 27.0904 292.179 27.8939 294.119C28.6975 296.059 29.8753 297.822 31.3601 299.307C32.8449 300.792 34.6077 301.969 36.5477 302.773C38.4877 303.577 40.567 303.99 42.6668 303.99C44.7666 303.99 46.8459 303.577 48.7859 302.773C50.7259 301.969 52.4887 300.792 53.9735 299.307L86.2935 267.008C180.011 173.269 331.989 173.269 425.707 267.008L458.027 299.307C461.026 302.306 465.093 303.99 469.333 303.99C473.574 303.99 477.641 302.306 480.64 299.307C483.639 296.308 485.323 292.241 485.323 288C485.323 283.759 483.639 279.692 480.64 276.693Z"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M402.133 146.496L370.133 201.92C368.208 205.585 367.775 209.853 368.926 213.829C370.078 217.805 372.724 221.182 376.309 223.251C379.895 225.319 384.143 225.92 388.161 224.926C392.18 223.932 395.658 221.422 397.867 217.92L429.867 162.496C431.988 158.818 432.562 154.449 431.462 150.348C430.362 146.247 427.678 142.751 424 140.629C420.322 138.508 415.953 137.934 411.852 139.034C407.751 140.134 404.255 142.818 402.133 146.496ZM240 117.333V181.333C240 185.577 241.686 189.647 244.686 192.647C247.687 195.648 251.757 197.333 256 197.333C260.244 197.333 264.313 195.648 267.314 192.647C270.314 189.647 272 185.577 272 181.333V117.333C272 113.09 270.314 109.02 267.314 106.02C264.313 103.019 260.244 101.333 256 101.333C251.757 101.333 247.687 103.019 244.686 106.02C241.686 109.02 240 113.09 240 117.333ZM82.1334 162.496L114.133 217.92C116.342 221.422 119.82 223.932 123.839 224.926C127.858 225.92 132.105 225.319 135.691 223.251C139.277 221.182 141.922 217.805 143.074 213.829C144.225 209.853 143.793 205.585 141.867 201.92L109.867 146.496C108.816 144.675 107.417 143.079 105.75 141.799C104.082 140.518 102.179 139.579 100.148 139.034C98.1179 138.489 96 138.35 93.9156 138.624C91.8312 138.897 89.8211 139.579 88.0001 140.629C86.1791 141.68 84.5829 143.079 83.3025 144.747C82.0222 146.414 81.0828 148.317 80.538 150.348C79.9933 152.378 79.8538 154.496 80.1275 156.581C80.4012 158.665 81.0829 160.675 82.1334 162.496Z"
                      />
                    </svg>
                  </div>
                  <div className="absolute backdrop-blur-sm rounded px-1 m-2 right-0 bg-white/50 dark:bg-black/50 h-4 w-12"></div>
                  <div className="absolute bottom-0 p-2 w-full flex flex-col gap-2 text-white">
                    <div class="h-8 bg-white/25 rounded w-[40%]"></div>
                    <div class="h-8 bg-white/25 rounded w-full"></div>
                    <div class="h-4 bg-white/25 rounded w-full mt-1"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="w-full my-16">
        <div className="font-semibold flex flex-row items-center justify-center">
          <div className="text-xl md:text-2xl lg:text-3xl transition-all ease-in-out duration-300 delay-0 transform dark:text-white">
            Ойртож буй шалгалтууд
          </div>
        </div>
        <div class="output"></div>
        <div className="relative w-full flex justify-center">
          {" "}
          <div className="hidden lg:flex w-full h-full absolute bg-gradient-to-r from-[#FFFFFF] dark:from-[#000000] via-[#ffffff00] dark:via-[#00000000] to-[#FFFFFF] dark:to-[#000000] z-20 pointer-events-none"></div>
          <div class="hidden lg:flex lg:w-[43%] xl:w-[38%] 2xl:w-[33%] pointer-events-none flex-row justify-between absolute items-center z-30 h-full">
            <button onClick={leftt}>
              <svg
                class="h-16 backdrop-blur-sm rounded-full drop-shadow-sm shadow-xl pointer-events-auto hover:backdrop-blur-2xl transition-all ease-in-out duration-500 delay-0 transform overflow-visible"
                viewBox="0 0 170 170"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M126 90C128.761 90 131 87.7614 131 85C131 82.2386 128.761 80 126 80V90ZM40.4645 81.4645C38.5118 83.4171 38.5118 86.5829 40.4645 88.5355L72.2843 120.355C74.2369 122.308 77.4027 122.308 79.3553 120.355C81.308 118.403 81.308 115.237 79.3553 113.284L51.0711 85L79.3553 56.7157C81.308 54.7631 81.308 51.5973 79.3553 49.6447C77.4027 47.692 74.2369 47.692 72.2843 49.6447L40.4645 81.4645ZM126 80L44 80V90L126 90V80Z"
                  fill="#4BAB4D"
                ></path>
              </svg>
            </button>
            <button onClick={rightt}>
              <svg
                class="h-16 backdrop-blur-sm rounded-full drop-shadow-sm shadow-xl pointer-events-auto hover:backdrop-blur-2xl transition-all ease-in-out duration-500 delay-0 transform overflow-visible"
                viewBox="0 0 170 170"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44 90C41.2386 90 39 87.7614 39 85C39 82.2386 41.2386 80 44 80V90ZM129.536 81.4645C131.488 83.4171 131.488 86.5829 129.536 88.5355L97.7157 120.355C95.7631 122.308 92.5973 122.308 90.6447 120.355C88.692 118.403 88.692 115.237 90.6447 113.284L118.929 85L90.6447 56.7157C88.692 54.7631 88.692 51.5973 90.6447 49.6447C92.5973 47.692 95.7631 47.692 97.7157 49.6447L129.536 81.4645ZM44 80L126 80V90L44 90V80Z"
                  fill="#4BAB4D"
                ></path>
              </svg>
            </button>
          </div>
          <div
            id="contentt"
            className="scroll-smooth lg:no-scrollbar flex flex-row gap-8 lg:gap-16 xl:gap-36 font-bold overflow-x-auto py-4 w-full overflow-y-hidden snap-x transition-all ease-in-out duration-300 delay-0 transform"
          >
            <div className="snap-center sm:ml-[28%] md:ml-[36%] lg:ml-[41%] transition-all ease-in-out duration-500 delay-0 transform">
              <div
                className={
                  qw
                    ? "flip-card transition-all ease-in-out duration-500 delay-0 transform"
                    : "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-0 transform lg:opacity-75 lg:blur-sm"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front h-[400px] lg:h-[450px]  border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex justify-center py-2 lg:py-4 h-16 lg:h-24">
                      <img src={sat} alt="" className="h-full object-cover" />
                    </div>
                    <div className="text-xl transition-all ease-in-out duration-500 delay-0 transform xl:text-2xl 2xl:text-2xl 3xl:text-3xl text-black flex justify-center flex-col items-center">
                      <div className=" flex flex-row items-center gap-2 scale-75">
                        11-р сарын 2
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={unavailableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="pt-2 flex flex-row items-center gap-2">
                        12-р сарын 7
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="pt-2 flex flex-row items-center gap-2 scale-75">
                        5-р сарын 3
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="pt-2 flex flex-row items-center gap-2 scale-75">
                        6-р сарын 7
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap pb-4 px-4 text-center md:text-lg xl:text-xl gap-x-1 xl:gap-x-2 text-black transition-all ease-in-out duration-500 delay-0 transform g-y-0">
                      <div className="flex items-center">Дараагийн</div>
                      <div className="flex items-center">шалгалт</div>
                      <div className="flex items-center">болоход</div>
                      <div>
                        <div className="text-base md:text-lg lg:text-xl xl:text-2xl transition-all ease-in-out duration-500 delay-0 transform py-1 px-1 2xl:px-2 bg-[#4BAB4D] rounded-lg text-[#FFFFFF]">
                          <Countdown targetDate="2024-12-07" />
                        </div>
                      </div>
                      <div className="flex items-center">үлдлээ</div>
                    </div>
                  </div>
                  <div className="flip-card-back h-[400px] lg:h-[450px] absolute top-0 w-56 xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white">
                    <div className="w-full flex justify-center py-2 lg:py-4 h-16 lg:h-24">
                      <img src={sat} alt="" className="h-full object-cover" />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg transition-all ease-in-out duration-500 delay-0 transform">
                      SAT (Scholastic Assessment Test) бол АНУ-д коллежид
                      элсэхэд өргөн хэрэглэгддэг стандартчилсан шалгалт юм.
                      Зарим их дээд сургуулиуд бакалаврын элсэлт авахдаа үүнийг
                      шаарддаг.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-0 transform lg:opacity-75 lg:blur-sm"
                    : "flip-card transition-all ease-in-out duration-500 delay-0 transform"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front h-[400px] lg:h-[450px]  border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex justify-center py-2 lg:py-4 h-16 lg:h-24">
                      <img
                        src={ielts}
                        alt=""
                        className="h-full object-cover p-1 bottom-0"
                      />
                    </div>
                    <div className="text-base transition-all ease-in-out duration-500 delay-0 transform  text-black w-full p-4 gap-2 flex flex-col">
                      <div>
                        <div className="w-full flex justify-end">11-р сар</div>
                        <div className="text-white flex flex-wrap gap-1 font-extrabold w-full">
                          <div className="text-center bg-black rounded-lg px-1">
                            2
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            6
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            7
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            15
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            16
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            22
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            23
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            27
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            29
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            30
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end">12-р сар</div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black rounded-lg px-1">
                            4
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            5
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            6
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            7
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            13
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            14
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            15
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            18
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            20
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            21
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            25
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            27
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            28
                          </div>
                        </div>
                      </div>
                      <div className="lg:block hidden">
                        <div className="w-full flex justify-end">1-р сар</div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black rounded-lg px-1">
                            3
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            10
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            17
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            18
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            24
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            25
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            29
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            31
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap pb-4 px-4 text-center md:text-lg xl:text-xl gap-x-1 xl:gap-x-2 text-black transition-all ease-in-out duration-500 delay-0 transform g-y-0">
                      <div className="flex items-center">Дараагийн</div>
                      <div className="flex items-center">шалгалт</div>
                      <div className="flex items-center">болоход</div>
                      <div>
                        <div className="text-base md:text-lg lg:text-xl xl:text-2xl transition-all ease-in-out duration-500 delay-0 transform py-1 px-1 2xl:px-2 bg-[#4BAB4D] rounded-lg text-[#FFFFFF]">
                          <Countdown targetDate="2024-11-06" />
                        </div>
                      </div>
                      <div className="flex items-center">үлдлээ</div>
                    </div>
                  </div>
                  <div className="flip-card-back h-[400px] lg:h-[450px]  absolute top-0 w-56 xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white">
                    <div className="w-full flex justify-center py-2 lg:py-4 h-16 lg:h-24">
                      <img
                        src={ielts}
                        alt=""
                        className="h-full object-cover p-1 bottom-0"
                      />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg transition-all ease-in-out duration-500 delay-0 transform">
                      IELTS (International English Language Testing System) нь
                      англи хэлний чадварын шалгалт бөгөөд гадаадад суралцах бас
                      ажиллаж амьдрах хүсэлтэй хүмүүст хамааралтай шалгалт юм.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card transition-all ease-in-out duration-500 delay-0 transform"
                    : "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-0 transform lg:opacity-75 lg:blur-sm"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front h-[400px] lg:h-[450px]  border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex justify-center py-2 lg:py-4 h-16 lg:h-24">
                      <img
                        src={topik}
                        alt=""
                        className=" object-cover h-full"
                      />
                    </div>
                    <div className="text-xl transition-all ease-in-out duration-500 delay-0 transform xl:text-2xl 2xl:text-2xl 3xl:text-3xl text-black flex justify-center flex-col items-center">
                      <div className=" flex flex-row items-center gap-2 opacity-40 scale-75">
                        9-р сарын 28
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={unavailableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2 opacity-40 scale-75">
                        10-р сарын 13
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={unavailableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2">
                        11-р сарын 10
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap pb-4 px-4 text-center md:text-lg xl:text-xl gap-x-1 xl:gap-x-2 text-black transition-all ease-in-out duration-500 delay-0 transform g-y-0">
                      <div className="flex items-center">Дараагийн</div>
                      <div className="flex items-center">шалгалт</div>
                      <div className="flex items-center">болоход</div>
                      <div>
                        <div className="text-base md:text-lg lg:text-xl xl:text-2xl transition-all ease-in-out duration-500 delay-0 transform py-1 px-1 2xl:px-2 bg-[#4BAB4D] rounded-lg text-[#FFFFFF]">
                          <Countdown targetDate="2024-11-10" />
                        </div>
                      </div>
                      <div className="flex items-center">үлдлээ</div>
                    </div>
                  </div>
                  <div className="flip-card-back h-[400px] lg:h-[450px]  absolute top-0 w-56 xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white">
                    <div className="w-full flex justify-center py-2 lg:py-4 h-16 lg:h-24">
                      <img
                        src={topik}
                        alt=""
                        className="h-full object-cover p-1 bottom-0"
                      />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg transition-all ease-in-out duration-500 delay-0 transform">
                      TEST OF PROFIENCY IN KOREA буюу TOPIK-ийн шалгалт нь
                      Солонгос хэлний төвшин тогтоох шалгалт юм. Солонгос улсын
                      боловсролын явцыг үнэлэх академиас хариуцан зохион
                      байгуулдаг гэрчилгээ олгодог шалгалт юм.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-0 transform lg:opacity-75 lg:blur-sm"
                    : "flip-card transition-all ease-in-out duration-500 delay-0 transform"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front h-[400px] lg:h-[450px]  border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex justify-center pt-3 h-20 lg:h-24">
                      <img src={hsk} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="text-xl transition-all ease-in-out duration-500 delay-0 transform xl:text-2xl 2xl:text-3xl 3xl:text-4xl text-black flex flex-col justify-between items-center">
                      <div className="py-2 flex flex-row items-center gap-2 scale-75">
                        10-р сарын 19
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={unavailableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2">
                        11-р сарын 16
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2 scale-75">
                        12-р сарын 8
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap pb-4 px-4 text-center md:text-lg xl:text-xl gap-x-1 xl:gap-x-2 text-black transition-all ease-in-out duration-500 delay-0 transform g-y-0">
                      <div className="flex items-center">Дараагийн</div>
                      <div className="flex items-center">шалгалт</div>
                      <div className="flex items-center">болоход</div>
                      <div>
                        <div className="text-base md:text-lg lg:text-xl xl:text-2xl transition-all ease-in-out duration-500 delay-0 transform py-1 px-1 2xl:px-2 bg-[#4BAB4D] rounded-lg text-[#FFFFFF]">
                          <Countdown targetDate="2024-11-16" />
                        </div>
                      </div>
                      <div className="flex items-center">үлдлээ</div>
                    </div>
                  </div>
                  <div className="flip-card-back h-[400px] lg:h-[450px]  absolute top-0 w-56 xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white">
                    <div className="w-full flex justify-center pt-3 h-20 lg:h-24">
                      <img src={hsk} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg transition-all ease-in-out duration-500 delay-0 transform">
                      HSK буюу Hanyu Shuiping Kaoshi нь нээлттэй хятад хэлний
                      анхан шатнаас ахисан түвшин хүртэл стандартыг тогтоодог
                      албан ёсны шалгалт юм. HSK нь анхан, дунд, гүнзгий гэсэн
                      гурван шатнаас бүрдэнэ.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card transition-all ease-in-out duration-500 delay-0 transform"
                    : "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-0 transform lg:opacity-75 lg:blur-sm"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front h-[400px] lg:h-[450px]  border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex justify-center pt-3 h-20 lg:h-24">
                      <img src={jlpt} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="text-xl transition-all ease-in-out duration-500 delay-0 transform xl:text-2xl 2xl:text-3xl 3xl:text-4xl text-black flex flex-col justify-between items-center">
                      <div className="py-2 flex flex-row items-center gap-2 scale-75">
                        7-р сарын 7
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={unavailableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className=" flex flex-row items-center gap-2">
                        12-р сарын 1
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={availableb}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap pb-4 px-4 text-center md:text-lg xl:text-xl gap-x-1 xl:gap-x-2 text-black transition-all ease-in-out duration-500 delay-0 transform g-y-0">
                      <div className="flex items-center">Дараагийн</div>
                      <div className="flex items-center">шалгалт</div>
                      <div className="flex items-center">болоход</div>
                      <div>
                        <div className="text-base md:text-lg lg:text-xl xl:text-2xl transition-all ease-in-out duration-500 delay-0 transform py-1 px-1 2xl:px-2 bg-[#4BAB4D] rounded-lg text-[#FFFFFF]">
                          <Countdown targetDate="2024-12-1" />
                        </div>
                      </div>
                      <div className="flex items-center">үлдлээ</div>
                    </div>
                  </div>
                  <div className="flip-card-back h-[400px] lg:h-[450px]  absolute top-0 w-56 xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white">
                    <div className="w-full flex justify-center pt-3 h-20 lg:h-24">
                      <img src={jlpt} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg transition-all ease-in-out duration-500 delay-0 transform">
                      JLPT (Japanese-Language Proficiency Test) Япон хэлний
                      түвшин тогтоох шалгалтыг Япон болон гадаадад эх хэлээр
                      ярьдаггүй хүмүүсийн япон хэлний мэдлэгийг үнэлж,
                      баталгаажуулах зорилгоор зохион байгуулдаг.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-0 transform lg:opacity-75 lg:blur-sm"
                    : "flip-card transition-all ease-in-out duration-500 delay-0 transform"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front h-[400px] lg:h-[450px]  border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex justify-center pt-3 h-20 lg:h-24">
                      <img
                        src={Goethe}
                        alt=""
                        className=" object-cover h-full"
                      />
                    </div>
                    <div className="text-xl transition-all ease-in-out duration-500 delay-0 transform xl:text-2xl 2xl:text-2xl 3xl:text-3xl text-black flex flex-col justify-between items-center">
                      <div className=" flex flex-row items-center gap-2 scale-75">
                        A1:8-р сарын 1
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={unavailableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2 scale-75">
                        A2:8-р сарын 5
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={unavailableb}
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="py-2 flex flex-row items-center gap-2 scale-75">
                        B1:8-р сарын 12
                        <div>
                          <img
                            className="h-4 xl:h-6 2xl:h-8 transition-all ease-in-out duration-500 delay-0 transform"
                            src={unavailableb}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap pb-4 px-4 text-center md:text-lg xl:text-xl gap-x-1 xl:gap-x-2 text-black transition-all ease-in-out duration-500 delay-0 transform g-y-0">
                      <div className="flex items-center">Дараагийн</div>
                      <div className="flex items-center">шалгалт</div>
                      <div className="flex items-center">болоход</div>
                      <div>
                        <div className="text-base md:text-lg lg:text-xl xl:text-2xl transition-all ease-in-out duration-500 delay-0 transform py-1 px-1 2xl:px-2 bg-[#4BAB4D] rounded-lg text-[#FFFFFF]">
                          <Countdown targetDate="2024-8-12" />
                        </div>
                      </div>
                      <div className="flex items-center">үлдлээ</div>
                    </div>
                  </div>
                  <div className="flip-card-back h-[400px] lg:h-[450px]  absolute top-0 w-56 xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white">
                    <div className="w-full flex justify-center pt-3 h-20 lg:h-24">
                      <img
                        src={Goethe}
                        alt=""
                        className=" object-cover h-full"
                      />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg transition-all ease-in-out duration-500 delay-0 transform">
                      Гёте-Институтийн шалгалтууд нь сонирхсон бүх хүнд нээлттэй
                      бөгөөд насанд хүрсэн эсэх, мөн ХБНГУ-ын иргэншилтэй
                      байхаас үл хамааран шалгалт өгөх боломжтой.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card transition-all ease-in-out duration-500 delay-0 transform"
                    : "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-0 transform lg:opacity-75 lg:blur-sm"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front h-[400px] lg:h-[450px]  border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex justify-center pt-4 h-16 lg:h-20">
                      <img src={ush} alt="" className=" object-cover h-full" />
                    </div>
                    <div className=" text-xs xl:text-sm transition-all ease-in-out duration-500 delay-0 transform text-black w-full p-4 gap-2 flex flex-col">
                      <div>
                        <div className="w-full flex justify-end text-base">
                          5-р анги
                        </div>
                        <div className="text-white flex flex-col gap-1 font-extrabold ">
                          <div className="text-center bg-black rounded-lg px-1">
                            Монгол хэл – 5.22
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            Математик – 5.24
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            Байгаль шинж. – 5.26
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          9-р анги
                        </div>
                        <div className="text-white flex flex-col gap-1 font-extrabold ">
                          <div className="text-center bg-black rounded-lg px-1">
                            Монгол хэл – 5.25
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            Математик – 5.26
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            Байгалийн у. – 5.29
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            Нийгмийн у. – 5.30
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          12-р анги
                        </div>
                        <div className="text-white flex flex-col gap-1 font-extrabold ">
                          <div className="text-center bg-black rounded-lg px-1">
                            Монгол хэл – 5.24
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            Математик – 5.25
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            Сонгосон агу. – 5.26
                          </div>
                          <div className="text-center bg-black rounded-lg px-1">
                            Гадаад хэл – 5.29
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex flex-wrap p-4 text-center text-lg transition-all ease-in-out duration-500 delay-0 transform xl:text-xl 2xl:text-2xl 3xl:text-3xl gap-x-1 xl:gap-x-2 text-black">
											<div className="flex items-center">Дараагийн</div>
											<div className="flex items-center">шалгалт</div>
											<div className="flex items-center">болоход</div>
											<div>
												<div className="text-xl lg:text-2xl xl:text-3xl transition-all ease-in-out duration-500 delay-0 transform py-1 px-1 2xl:px-2 bg-black rounded-lg text-[#FFFFFF]">
													47 өдөр
												</div>
											</div>
											<div className="flex items-center">үлдлээ</div>
										</div> */}
                  </div>
                  <div className="flip-card-back h-[400px] lg:h-[450px]  absolute top-0 w-56 xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white">
                    <div className="w-full flex justify-center pt-4 h-16 lg:h-20">
                      <img src={ush} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg transition-all ease-in-out duration-500 delay-0 transform">
                      Бага, суурь, бүрэн дунд боловсролын сургалтын хөтөлбөрийн
                      хэрэгжилтийн түвшинг тогтоох, төгсөх ангийн суралцагчдын
                      мэдлэг, чадварыг бодитойгоор үнэлэх зорилготой.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-0 transform lg:opacity-75 lg:blur-sm"
                    : "flip-card transition-all ease-in-out duration-500 delay-0 transform"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front h-[400px] lg:h-[450px]  border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex justify-center pt-3 h-16 lg:h-20">
                      <img src={esh} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="xl:text-lg transition-all ease-in-out duration-500 delay-0 transform text-black w-full p-4 gap-2 flex flex-col">
                      <div>
                        <div className="w-full flex justify-end text-base">
                          12.05
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black rounded-lg px-2">
                            Түүх
                          </div>
                          <div className="text-center bg-black rounded-lg px-2">
                            Англи Хэл
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          12.06
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black rounded-lg px-2">
                            Газар Зүй
                          </div>
                          <div className="text-center bg-black rounded-lg px-2">
                            Нийгэм
                          </div>
                          <div className="text-center bg-black rounded-lg px-2">
                            Хими
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          12.07
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black rounded-lg px-2">
                            Биологи
                          </div>
                          <div className="text-center bg-black rounded-lg px-2">
                            Математик
                          </div>
                          <div className="text-center bg-black rounded-lg px-2">
                            Орос хэл
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          12.08
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold ">
                          <div className="text-center bg-black rounded-lg px-2">
                            Физик
                          </div>
                          <div className="text-center bg-black rounded-lg px-2">
                            Монгол Хэл
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back h-[400px] lg:h-[450px]  absolute top-0 w-56 xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white">
                    <div className="w-full flex justify-center pt-4 h-20 lg:h-24">
                      <img src={esh} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg transition-all ease-in-out duration-500 delay-0 transform">
                      ЭЕШ (Элсэлтийн Ерөнхий Шалгалт) нь Монгол улсын их, дээд
                      сургуулиудад элсэхэд ашиглагддаг стандартчилсан шалгалт юм
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center">
              <div
                className={
                  qw
                    ? "flip-card transition-all ease-in-out duration-500 delay-0 transform"
                    : "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-0 transform lg:opacity-75 lg:blur-sm"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front h-[400px] lg:h-[450px]  border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex justify-center pt-2 h-14 md:h-16 lg:h-20">
                      <img src={oge} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="xl:text-lg transition-all ease-in-out duration-500 delay-0 transform text-black w-full p-4 gap-2 flex flex-col">
                      <div>
                        <div className="w-full flex justify-end text-base">
                          05.21-22
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black rounded-lg px-2">
                            Иностр. языки
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          05.27
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black rounded-lg px-2">
                            Биология
                          </div>
                          <div className="text-center bg-black rounded-lg px-2">
                            Химия
                          </div>
                          <div className="text-center bg-black rounded-lg px-2">
                            Инфо.
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          05.30
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black rounded-lg px-2">
                            География
                          </div>
                          <div className="text-center bg-black rounded-lg px-2">
                            Физика
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          06.03
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold ">
                          <div className="text-center bg-black rounded-lg px-2">
                            Русский язык
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          06.06
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold ">
                          <div className="text-center bg-black rounded-lg px-2">
                            Математика
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back h-[400px] lg:h-[450px]  absolute top-0 w-56 xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white">
                    <div className="w-full flex justify-center pt-2 h-14 md:h-16 lg:h-20">
                      <img src={oge} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg transition-all ease-in-out duration-500 delay-0 transform">
                      ОГЭ бол 9-р ангийн сурагчид өгдөг Оросын ерөнхий шалгалт
                      юм. ОГЭ-д тэнцэх нь ерөнхий боловсролын гэрчилгээ авах
                      эрхийг нээлттэй болгодог ба нийт дөрвөн хичээлийг давах
                      шаардлагатай: Орос хэл, математик болон үлдсэн хоёр нь
                      сурагчийн сонголт байдаг.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="snap-center mr-[28%] sm:mr-[30%] md:mr-[36%] lg:mr-[41%]">
              <div
                className={
                  qw
                    ? "flip-card lg:scale-75 transition-all ease-in-out duration-500 delay-0 transform lg:opacity-75 lg:blur-sm"
                    : "flip-card transition-all ease-in-out duration-500 delay-0 transform"
                }
              >
                <div className="flip-card-inner relative w-56 xl:w-64 2xl:w-72 cursor-pointer">
                  <div className="flip-card-front h-[400px] lg:h-[450px]  border-2 w-56 xl:w-64 2xl:w-72 rounded-lg bg-white whitespace-normal flex flex-col justify-between items-center">
                    <div className="w-full flex justify-center pt-2 h-14 md:h-16 lg:h-20">
                      <img src={ege} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="xl:text-lg transition-all ease-in-out duration-500 delay-0 transform text-black w-full px-4 pb-4 xl:gap-1.5 flex flex-col">
                      <div>
                        <div className="w-full flex justify-end text-base">
                          05.23
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black rounded-lg px-2">
                            Геогр.
                          </div>
                          <div className="text-center bg-black rounded-lg px-2">
                            Литер.
                          </div>
                          <div className="text-center bg-black rounded-lg px-2">
                            Химия
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          05.28
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black rounded-lg px-2">
                            Русский язык
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          05.31
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold flex-wrap">
                          <div className="text-center bg-black rounded-lg px-2">
                            Математика
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          06.04
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold ">
                          <div className="text-center bg-black rounded-lg px-2">
                            Физика
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          06.07-08
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold ">
                          <div className="text-center bg-black rounded-lg px-2">
                            Информатика
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="w-full flex justify-end text-base">
                          06.11
                        </div>
                        <div className="text-white flex flex-row gap-1 font-extrabold ">
                          <div className="text-center bg-black rounded-lg px-2">
                            Иностр. языки
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flip-card-back h-[400px] lg:h-[450px]  absolute top-0 w-56 xl:w-64 2xl:w-72 border-2 rounded-lg flex flex-col justify-between bg-white">
                    <div className="w-full flex justify-center pt-2 h-14 md:h-16 lg:h-20">
                      <img src={ege} alt="" className=" object-cover h-full" />
                    </div>
                    <div className="pb-4 px-4 lg:text-md xl:text-lg transition-all ease-in-out duration-500 delay-0 transform">
                      ЕГЭ бол 11-р ангийн сурагчид өгдөг Оросын улсын шалгалт
                      юм. ЕГЭ-д тэнцэхэд математик болон орос хэлний
                      шалгалтуудыг давснаар гэрчилгээгээ авах юм.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center my-16 w-full relative">
        <div className="font-semibold flex flex-row items-center justify-center mb-4 relative w-full">
          <div className="absolute">
            <svg
              width="268"
              height="102"
              viewBox="0 0 268 102"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M226.911 42.2033C227.325 42.354 227.783 42.1406 227.934 41.7267C228.084 41.3128 227.871 40.8551 227.457 40.7045C224.639 39.6787 223.186 36.5625 224.211 33.7442L224.231 33.6906C224.381 33.2767 224.168 32.8191 223.754 32.6684C223.34 32.5178 222.883 32.7312 222.732 33.1451C221.695 35.993 218.502 37.4451 215.654 36.4086C215.24 36.2579 214.782 36.4713 214.632 36.8852C214.481 37.2991 214.695 37.7568 215.109 37.9074L215.187 37.936C218.016 38.9657 219.475 42.0939 218.445 44.923C218.295 45.3369 218.508 45.7946 218.922 45.9452C219.336 46.0959 219.793 45.8824 219.944 45.4685C220.97 42.6503 224.093 41.1776 226.911 42.2033Z"
                fill="#4BAB4D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M71.9715 25.7442C72.4591 25.4849 72.6442 24.8794 72.385 24.3918C72.1257 23.9042 71.5202 23.719 71.0325 23.9783C67.7122 25.7438 63.5894 24.4833 61.824 21.163L61.7904 21.0998C61.5311 20.6122 60.9256 20.427 60.438 20.6863C59.9504 20.9456 59.7652 21.5511 60.0245 22.0387C61.8085 25.3939 60.4821 29.5881 57.1269 31.3721C56.6392 31.6314 56.4541 32.2369 56.7134 32.7245C56.9727 33.2122 57.5782 33.3973 58.0658 33.138L58.1584 33.0887C61.4916 31.3165 65.6304 32.5818 67.4026 35.915C67.6619 36.4026 68.2674 36.5877 68.7551 36.3285C69.2427 36.0692 69.4278 35.4637 69.1685 34.976C67.4031 31.6558 68.6512 27.5097 71.9715 25.7442Z"
                fill="#4BAB4D"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M44.2909 84.1501C44.5777 84.983 45.4854 85.4258 46.3183 85.139C47.1512 84.8522 47.594 83.9444 47.3072 83.1115L46.3758 80.4066C44.9373 76.229 47.1578 71.6764 51.3354 70.2379L54.1481 69.2694C54.981 68.9826 55.4237 68.0749 55.1369 67.242C54.8501 66.4091 53.9424 65.9663 53.1095 66.2531L50.2968 67.2216C46.1192 68.6601 41.5666 66.4396 40.1281 62.262L39.1287 59.3594C38.8419 58.5265 37.9341 58.0837 37.1012 58.3705C36.2683 58.6573 35.8255 59.5651 36.1123 60.398L37.1118 63.3006C38.5502 67.4782 36.3298 72.0308 32.1522 73.4693L29.4076 74.4143C28.5747 74.7011 28.1319 75.6089 28.4187 76.4418C28.7055 77.2747 29.6133 77.7174 30.4462 77.4306L33.1908 76.4856C37.3684 75.0472 41.921 77.2676 43.3595 81.4452L44.2909 84.1501Z"
                fill="#4BAB4D"
              />
            </svg>
          </div>
          <a
            href="/noots/hiih_zuils"
            className="text-xl md:text-2xl lg:text-3xl transition-all ease-in-out duration-300 delay-0 cursor-pointer transform dark:text-white"
          >
            To Do List
          </a>
          <a
            href="/noots/hiih_zuils"
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0 font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <a
          href="/noots/hiih_zuils"
          className="sm:text-lg text-center items-center md:hidden flex font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
        >
          Дэлгэрэнгүй{" "}
          <svg
            className="h-4"
            viewBox="0 0 366 359"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1639_2733)">
              <path
                d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
              />
            </g>
            <defs>
              <clipPath id="clip0_1639_2733">
                <rect width="366" height="359" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </a>
        <div className="flex flex-wrap w-full lg:flex-row justify-evenly m-4 transition-all ease-in-out duration-300 delay-0 transform">
          <a
            href="/noots/hiih_zuils/0"
            className="w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-150 duration-500 ease-in-out bg-repeat todo hover:bg-white hover:text-black dark:hover:bg-white/90 bg-black text-white cursor-pointer border-[#97b85190] my-2"
          >
            <div className="text-md lg:text-xl font-bold xl:text-2xl text text-center drop-shadow-lg">
              10 ба түүнээс доош
            </div>
          </a>
          <a
            href="/noots/hiih_zuils/1"
            className="w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-150 duration-500 ease-in-out bg-repeat todo1 hover:bg-white hover:text-black dark:hover:bg-white/90 bg-black text-white cursor-pointer border-[#97b85190] my-2"
          >
            <div className="text-md lg:text-xl font-bold xl:text-2xl text-center drop-shadow-lg">
              11-р анги
            </div>
          </a>
          <a
            href="/noots/hiih_zuils/2"
            className="w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-150 duration-500 ease-in-out bg-repeat todo hover:bg-white hover:text-black dark:hover:bg-white/90 bg-black text-white cursor-pointer border-[#97b85190] my-2"
          >
            <div className="text-md lg:text-xl  font-bold xl:text-2xl text-center drop-shadow-lg">
              12-р анги
            </div>
          </a>
          <a
            href="/noots/hiih_zuils/3"
            className="w-full h-16 rounded-lg border-2 flex justify-center items-center transition delay-150 duration-500 ease-in-out bg-repeat todo1 hover:bg-white hover:text-black dark:hover:bg-white/90 bg-black text-white cursor-pointer border-[#97b85190] my-2"
          >
            <div className="text-md lg:text-xl  font-bold xl:text-2xl text-center drop-shadow-lg">
              Завсар жил
            </div>
          </a>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center my-16 dark:text-white">
        <div className="font-semibold flex flex-col items-center justify-center mb-4 w-full relative">
          <a
            href="/noots/surgalt"
            className="text-xl md:text-2xl lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform"
          >
            Бэлдэхэд туслах <br /> сургалтууд
          </a>
          <a
            href="/noots/surgalt"
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0 font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
          <a
            href="/noots/surgalt"
            className="sm:text-lg text-center items-center md:hidden flex font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div className=" flex flex-row  overflow-x-auto w-full gap-4">
          {news.length > 0 ? (
            <>
              {acad.map((ell, index) => (
                <div
                  onClick={() => {
                    window.location.pathname = `/noots/surgalt/${ell._id}`;
                  }}
                >
                  <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform">
                    <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                      <img
                        className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform"
                        src={
                          "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Acad%2F" +
                          ell.img
                        }
                        alt=""
                      />
                      <div className="text-[#656366] dark:text-[#9f9da0] p-2 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0">
                        {ell.ca}
                      </div>
                    </div>

                    <div className="w-full rounded-lg">
                      <div className="font-bold text-xl lg:text-2xl truncate">
                        {ell.na}
                      </div>
                      <div className="font-normal text-md lg:text-lg text-[#7c7c7c] truncate">
                        {ell.di.replace(/\s*\/N\s*/g, "\n")}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className=" flex flex-row  w-full gap-4">
              <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform animate-pulse">
                <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:bg-white/25 bg-black/25"></div>
                  <div className="text-[#656366] dark:text-[#9f9da0] p-2 w-20 h-9 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0"></div>
                </div>
                <div className="w-full rounded-lg h-12 dark:bg-white/25 bg-black/25 mt-2"></div>
              </div>
              <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform animate-pulse">
                <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:bg-white/25 bg-black/25"></div>
                  <div className="text-[#656366] dark:text-[#9f9da0] p-2 w-20 h-9 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0"></div>
                </div>
                <div className="w-full rounded-lg h-12 dark:bg-white/25 bg-black/25 mt-2"></div>
              </div>
              <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform animate-pulse">
                <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:bg-white/25 bg-black/25"></div>
                  <div className="text-[#656366] dark:text-[#9f9da0] p-2 w-20 h-9 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0"></div>
                </div>
                <div className="w-full rounded-lg h-12 dark:bg-white/25 bg-black/25 mt-2"></div>
              </div>
              <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform animate-pulse">
                <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:bg-white/25 bg-black/25"></div>
                  <div className="text-[#656366] dark:text-[#9f9da0] p-2 w-20 h-9 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0"></div>
                </div>
                <div className="w-full rounded-lg h-12 dark:bg-white/25 bg-black/25 mt-2"></div>
              </div>
              <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform animate-pulse">
                <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:bg-white/25 bg-black/25"></div>
                  <div className="text-[#656366] dark:text-[#9f9da0] p-2 w-20 h-9 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0"></div>
                </div>
                <div className="w-full rounded-lg h-12 dark:bg-white/25 bg-black/25 mt-2"></div>
              </div>
              <div className="w-80 lg:w-96 rounded-lg flex flex-col cursor-pointer transition-all ease-in-out duration-300 delay-0 transform animate-pulse">
                <div className="relative w-80 h-80 lg:w-96 lg:h-96 transition-all ease-in-out duration-300 delay-0 transform">
                  <div className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover rounded-lg transition-all ease-in-out duration-300 delay-0 transform dark:bg-white/25 bg-black/25"></div>
                  <div className="text-[#656366] dark:text-[#9f9da0] p-2 w-20 h-9 absolute border-none rounded-tl-lg bg-white dark:bg-black bottom-0 right-0"></div>
                </div>
                <div className="w-full rounded-lg h-12 dark:bg-white/25 bg-black/25 mt-2"></div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col items-center justify-center my-16 ">
        <div className="font-semibold flex flex-col items-center justify-center mb-4 w-full relative">
          <a
            href="/sain_duriin_ajil"
            className="text-xl md:text-2xl lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform dark:text-white"
          >
            Хичээлээс гадуурх <br />
            үйл ажиллагаа
          </a>
          <div className="text-lg lg:text-xl text-[#7c7c7c] dark:text-[#c6c6c6] transition-all ease-in-out duration-300 delay-0 transform">
            Чиглэлээ сонгон уу
          </div>
          <a
            href="/sain_duriin_ajil"
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0 font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
          <a
            href="/sain_duriin_ajil"
            className="sm:text-lg text-center items-center md:hidden flex font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>

        <div className="flex flex-row w-full xl:flex-wrap xl:justify-center items-center my-4 overflow-y-auto relative xl:h-[50vh] 2xl:h-full dark:text-white">
          {/* {mergejil.map((el, key) => {
            // console.log(el);
            return (
              <a
                href="/sain_duriin_ajil/emch"
                className="flex justify-center items-center group/item cursor-pointer p-8"
              >
                <div
                  key={key}
                  className="rounded-2xl w-32 h-32 ring-4 ring-[#656366] rotate-45 group-hover/item:rotate-0 group-hover/item:ring-[#4BAB4D] transition delay-150 duration-500 ease-in-out"
                ></div>
                <div className="h-32 w-32 flex items-end justify-end capitalize font-semibold transition-all ease-in-out duration-500 absolute opacity-25 group-hover/item:rotate-0 rotate-45 z-0 delay-150 group-hover/item:z-10 text-right group-hover/item:opacity-100 drop-shadow">
                  <div class="rounded-2xl px-2 backdrop-blur-sm bg-[#ffffff60] dark:bg-[#00000060] w-full">
                    {el.ajil}
                  </div>
                </div>
                <img
                  src={el.ner}
                  className="w-24 h-24 absolute mb-6 group-hover/item:mb-0 group-hover/item:grayscale-0 transition-all delay-150 duration-500 ease-in-out grayscale "
                  alt=""
                />
              </a>
            );
          })} */}
          {prof.map((el, key) => {
            // console.log(el);
            return (
              <div
                onClick={() => {
                  window.location.pathname = `/sain_duriin_ajil/${el._id}`;
                }}
                className="flex justify-center items-center group/item cursor-pointer p-8"
              >
                <div
                  key={key}
                  className="rounded-2xl w-32 h-32 ring-4 ring-[#656366] rotate-45 group-hover/item:rotate-0 group-hover/item:ring-[#4BAB4D] transition delay-150 duration-500 ease-in-out"
                ></div>
                <div className="h-32 w-32 flex items-end justify-end capitalize font-semibold transition-all ease-in-out duration-500 absolute opacity-25 group-hover/item:rotate-0 rotate-45 z-0 delay-150 group-hover/item:z-10 text-right group-hover/item:opacity-100 drop-shadow">
                  <div class="rounded-2xl px-2 backdrop-blur-sm bg-[#ffffff60] dark:bg-[#00000060] w-full">
                    {el.na}
                  </div>
                </div>
                <img
                  src={el.img}
                  className="w-24 h-24 absolute mb-6 group-hover/item:mb-0 group-hover/item:grayscale-0 transition-all delay-150 duration-500 ease-in-out grayscale "
                  alt=""
                />
              </div>
            );
          })}
        </div>
      </div>
      <div class="flex flex-col items-center justify-center my-8 lg:my-16">
        <div class="font-semibold flex flex-col items-center justify-center mb-4 overflow-visible relative w-full">
          <a
            href="/noots/uil_ajillagaa"
            class="text-xl md:text-2xl lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform dark:text-white"
          >
            Ойртож буй арга хэмжээ болон <br /> сайн дурын үйл ажиллагаанууд
          </a>
          <div class="text-lg lg:text-xl text-[#7c7c7c] dark:text-[#c6c6c6] text-center transition-all ease-in-out duration-300 delay-0 transform">
            Эдгээр үйл ажиллагаанд идэвхтэй оролцоорой
          </div>
          <a
            href="/noots/uil_ajillagaa"
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0 font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
          <a
            href="/sain_duriin_ajil"
            className="sm:text-lg text-center items-center md:hidden flex font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div class="w-full flex items-center justify-center relative">
          <div class="hidden lg:flex w-[95%] 2xl:w-[85%] pointer-events-none flex-row justify-between absolute items-center z-30 h-full">
            <button onClick={left}>
              <svg
                class="h-16 backdrop-blur-sm rounded-full drop-shadow-sm shadow-xl pointer-events-auto hover:backdrop-blur-2xl transition-all ease-in-out duration-500 delay-0 transform overflow-visible"
                viewBox="0 0 170 170"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M126 90C128.761 90 131 87.7614 131 85C131 82.2386 128.761 80 126 80V90ZM40.4645 81.4645C38.5118 83.4171 38.5118 86.5829 40.4645 88.5355L72.2843 120.355C74.2369 122.308 77.4027 122.308 79.3553 120.355C81.308 118.403 81.308 115.237 79.3553 113.284L51.0711 85L79.3553 56.7157C81.308 54.7631 81.308 51.5973 79.3553 49.6447C77.4027 47.692 74.2369 47.692 72.2843 49.6447L40.4645 81.4645ZM126 80L44 80V90L126 90V80Z"
                  fill="#4BAB4D"
                ></path>
              </svg>
            </button>
            <button onClick={right}>
              <svg
                class="h-16 backdrop-blur-sm rounded-full drop-shadow-sm shadow-xl pointer-events-auto hover:backdrop-blur-2xl transition-all ease-in-out duration-500 delay-0 transform overflow-visible"
                viewBox="0 0 170 170"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M44 90C41.2386 90 39 87.7614 39 85C39 82.2386 41.2386 80 44 80V90ZM129.536 81.4645C131.488 83.4171 131.488 86.5829 129.536 88.5355L97.7157 120.355C95.7631 122.308 92.5973 122.308 90.6447 120.355C88.692 118.403 88.692 115.237 90.6447 113.284L118.929 85L90.6447 56.7157C88.692 54.7631 88.692 51.5973 90.6447 49.6447C92.5973 47.692 95.7631 47.692 97.7157 49.6447L129.536 81.4645ZM44 80L126 80V90L44 90V80Z"
                  fill="#4BAB4D"
                ></path>
              </svg>
            </button>
          </div>
          <div
            id="content"
            class="relative w-full flex gap-4 snap-x snap-mandatory overflow-x-auto scroll-smooth md:items-center dark:text-white"
          >
            {event.length > 0 ? (
              <>
                {event.map((el) => (
                  <a
                    href={"/noots/uil_ajillagaa/" + el._id}
                    class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]"
                  >
                    <div>
                      <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                        <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                          <div class="flex flex-col items-center justify-between">
                            <div class="flex flex-col xl:flex-row items-center font-semibold">
                              <div class="w-80 lg:w-72 2xl:w-80">
                                <img
                                  class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg"
                                  src={
                                    "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/Events%2F" +
                                    el.i
                                  }
                                  alt=""
                                />
                              </div>
                              <div class="lg:ml-2 xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                                <div class="text-lg xl:text-xl lg:hidden xl:block">
                                  {el.n}
                                </div>
                                <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex lg:hidden xl:flex">
                                  <div>
                                    {new Date(el.sT).getUTCDay() === 1
                                      ? "Дав, "
                                      : new Date(el.sT).getUTCDay() === 2
                                      ? "Мяг, "
                                      : new Date(el.sT).getUTCDay() === 3
                                      ? "Лха, "
                                      : new Date(el.sT).getUTCDay() === 4
                                      ? "Пүр, "
                                      : new Date(el.sT).getUTCDay() === 5
                                      ? "Баа, "
                                      : new Date(el.sT).getUTCDay() === 6
                                      ? "Бям, "
                                      : new Date(el.sT).getUTCDay() === 7
                                      ? "Ням, "
                                      : ""}
                                    {new Date(el.sT).getUTCMonth() +
                                      1 +
                                      "-р сарын " +
                                      new Date(el.sT).getUTCDate() +
                                      ", " +
                                      new Date(el.sT).getUTCFullYear()}
                                  </div>
                                  <div>
                                    {new Date(el.sT).getUTCHours() +
                                      ":" +
                                      (new Date(el.sT).getUTCMinutes() === 0
                                        ? "00"
                                        : new Date(el.sT).getUTCMinutes())}
                                  </div>
                                </div>
                                <div class="xl:text-md h-40 overflow-hidden relative">
                                  <div className="bg-gradient-to-t from-white dark:from-black via-transparent to-transparent h-full w-full absolute"></div>
                                  {el.nf}
                                </div>
                              </div>
                            </div>
                            <div class="mt-4 w-full hidden xl:block font-semibold">
                              <button
                                onClick={() => {
                                  if (el.r === "n") {
                                    return 0;
                                  } else {
                                    window.open(el.r);
                                  }
                                }}
                                class={
                                  el.r === "n"
                                    ? "p-2 text-[#4BAB4D] rounded-lg w-full bg-[#027f0018] cursor-not-allowed"
                                    : "p-2 bg-[#4BAB4D] rounded-lg w-full text-white cursor-pointer"
                                }
                              >
                                Бүртгүүлэх{" "}
                                {el.r === "n" ? " шаардлагагүй " : ""}
                              </button>
                            </div>
                          </div>
                          <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                            <div class="hidden lg:block xl:hidden font-semibold">
                              <div class="text-md lg:text-lg xl:text-xl">
                                {el.n}
                              </div>
                              <div class="text-[#656366] text-sm 2xl:text-base flex flex-row justify-between">
                                <div>
                                  {new Date(el.sT).getUTCDay() === 1
                                    ? "Дав, "
                                    : new Date(el.sT).getUTCDay() === 2
                                    ? "Мяг, "
                                    : new Date(el.sT).getUTCDay() === 3
                                    ? "Лха, "
                                    : new Date(el.sT).getUTCDay() === 4
                                    ? "Пүр, "
                                    : new Date(el.sT).getUTCDay() === 5
                                    ? "Баа, "
                                    : new Date(el.sT).getUTCDay() === 6
                                    ? "Бям, "
                                    : new Date(el.sT).getUTCDay() === 7
                                    ? "Ням, "
                                    : ""}
                                  {new Date(el.sT).getUTCMonth() +
                                    1 +
                                    "-р сарын " +
                                    new Date(el.sT).getUTCDate() +
                                    ", " +
                                    new Date(el.sT).getUTCFullYear()}
                                </div>
                                <div>
                                  {new Date(el.sT).getUTCHours() +
                                    ":" +
                                    (new Date(el.sT).getUTCMinutes() === 0
                                      ? "00"
                                      : new Date(el.sT).getUTCMinutes())}
                                </div>
                              </div>
                            </div>
                            {el.d.map((ell, index) => (
                              <div class=" flex flex-wrap justify-between font-semibold w-full gap-y-1">
                                <div class="">{Object.keys(ell)}:</div>
                                <div
                                  style={{
                                    backgroundColor:
                                      colors[index % colors.length],
                                  }}
                                  class="text-sm px-1 xl:p-1 rounded-lg text-white flex justify-center items-center"
                                >
                                  {ell[Object.keys(ell)[0]]}
                                </div>
                              </div>
                            ))}
                            <button
                              onClick={() => {
                                if (el.r === "n") {
                                  return 0;
                                } else {
                                  window.open(el.r);
                                }
                              }}
                              class={
                                el.r === "n"
                                  ? "block xl:hidden p-2 text-[#4BAB4D] rounded-lg w-full bg-[#027f0018] cursor-not-allowed"
                                  : "block xl:hidden p-2 bg-[#4BAB4D] rounded-lg w-full text-white cursor-pointer"
                              }
                            >
                              Бүртгүүлэх {el.r === "n" ? " шаардлагагүй " : ""}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </>
            ) : (
              <>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center font-semibold">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block font-semibold">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center font-semibold">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block font-semibold">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center font-semibold">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block font-semibold">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center font-semibold">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block font-semibold">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="snap-always snap-center shrink-0 last:sm:mr-[10%] first:sm:ml-[10%] first:md:ml-[14%] last:md:mr-[14%] first:lg:ml-[20%] last:lg:mr-[20%]">
                  <div>
                    <div class="md:border-2 dark:border-zinc-800 rounded-lg">
                      <div class="rounded-lg flex flex-col lg:flex-row cursor-pointer md:p-4 lg:gap-4 gap-1">
                        <div class="flex flex-col items-center justify-between">
                          <div class="flex flex-col xl:flex-row items-center font-semibold">
                            <div class="w-80 lg:w-72 2xl:w-80 lg:mb-0 mb-2">
                              <div class="w-80 lg:w-72 2xl:w-80 h-48 xl:h-52 2xl:h-56 object-cover rounded-lg bg-black/25 dark:bg-white/25"></div>
                            </div>
                            <div class=" xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-lg xl:text-xl lg:hidden xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                                <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="xl:text-md h-28 mt-2 rounded-lg w-full overflow-hidden relative bg-black/25 dark:bg-white/25"></div>
                            </div>
                          </div>
                          <div class="mt-4 w-full hidden xl:block font-semibold">
                            <button class="p-2 bg-black/25 dark:bg-white/25 rounded-lg w-full cursor-not-allowed h-10"></button>
                          </div>
                        </div>
                        <div class="w-80 lg:w-72 2xl:w-80 flex flex-col text-wrap justify-between h-auto lg:text-md gap-y-1 lg:gap-y-0">
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>
                          <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 lg:hidden xl:flex">
                            <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                          </div>

                          <div class="xl:hidden lg:block hidden xl:ml-4 w-80 lg:w-72 2xl:w-80 text-justify">
                            <div class="text-lg xl:text-xl xl:block w-full bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-lg xl:text-xl xl:block w-[40%] mt-1 bg-black/25 dark:bg-white/25 h-8 rounded-lg"></div>
                            <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                              <div className="w-[30%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                            </div>
                            <div className="flex flex-col h-40 w-full justify-between">
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                              <div class="text-[#656366] dark:text-[#a7a7a7] text-sm lg:text-base flex-row justify-between flex mt-2 xl:flex">
                                <div className="w-[40%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                                <div className="w-[20%] bg-black/25 dark:bg-white/25 h-5 rounded"></div>
                              </div>
                            </div>
                          </div>
                          <button class="block xl:hidden p-2 bg-black/25 dark:bg-white/25 rounded-lg h-10 w-full cursor-not-allowed"></button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center my-16 ">
        <div className="font-semibold flex flex-col items-center justify-center mb-4 w-full relative">
          <a
            href="/noots/sur_dataset"
            className="text-xl md:text-2xl lg:text-3xl text-center transition-all ease-in-out duration-300 delay-0 transform dark:text-white"
          >
            Топ Сургуулиудын <br /> Мэдээлэл
          </a>
          <a
            href="/noots/sur_dataset"
            className="sm:text-lg text-center items-center hidden md:flex absolute right-0 font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
          <a
            href="/noots/sur_dataset"
            className="sm:text-lg text-center items-center md:hidden flex font-semibold md:text-xl group/item flex-row gap-2 hover:gap-0 transition-all ease-in-out duration-300 delay-0 transform text-neutral-600 dark:text-neutral-300 dark:hover:text-[#98B851]  hover:text-[#4BAB4D] cursor-pointer"
          >
            Дэлгэрэнгүй{" "}
            <svg
              className="h-4"
              viewBox="0 0 366 359"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1639_2733)">
                <path
                  d="M186.935 358.886L145.741 318.048L253.874 209.916H0.5V149.902H253.874L145.741 41.9472L186.935 0.931641L365.912 179.909L186.935 358.886Z"
                  className="group-hover/item:fill-[#4BAB4D] dark:group-hover/item:fill-[#98B851] fill-[#525252] dark:fill-neutral-300 transition-all ease-in-out duration-300 delay-0 transform"
                />
              </g>
              <defs>
                <clipPath id="clip0_1639_2733">
                  <rect width="366" height="359" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
        <div className=" flex flex-row scroll-smooth overflow-x-auto w-full gap-4">
          {school.map((el) => (
            <a href={"/noots/sur_dataset/" + el._id} className="group/item ">
              <div className="group-hover/item:rounded-3xl rounded-full overflow-hidden transition-all ease-in-out duration-700 delay-0 lg:group-hover/item:mx-8">
                <div className="w-80 lg:w-96 flex flex-col cursor-pointer justify-center items-center">
                  <div className="relative w-80 h-80 lg:w-96 lg:h-96 flex ">
                    <img
                      className="absolute w-80 h-80 lg:w-96 lg:h-96 object-cover brightness-75 group-hover/item:brightness-100 transition-all ease-in-out duration-1000 delay-0"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/sch%2F" +
                        el.img
                      }
                      alt=""
                    />
                    <div className="bg-gradient-to-t from-[#000000] to-[#00000000] group-hover/item:h-[50%] h-[70%] w-full absolute bottom-0 transition-all ease-in-out duration-1000 delay-0 transform"></div>
                    <div className="absolute text-white font-semibold pt-10 text-lg lg:text-xl w-full justify-center items-center flex group-hover/item:drop-shadow-lg group-hover/item:pt-4 transition-all ease-in-out duration-1000 delay-0 transform">
                      <div className="backdrop-blur-sm max-w-[50%] group-hover/item:bg-[#0000001f] group-hover/item:px-2 group-hover/item:rounded-lg transition-all ease-in-out duration-1000 delay-0 transform">
                        RD: {el.appl[3].RD[0].deadline}
                      </div>
                    </div>
                    <div className="text-white font-semibold p-8 text-xl md:text-2xl lg:text-3xl flex flex-row w-full h-full justify-between transition-all items-center ease-in-out duration-1000 delay-0 transform group-hover/item:mt-[30%] ">
                      <div className="w-[70%]">{el.Uname}</div>
                      <div className="text-sm lg:text-base flex items-center flex-col right-0 justify-center">
                        <div class="text-yellow-400">
                          <svg
                            class="flex-shrink-0 size-5"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            viewBox="0 0 16 16"
                          >
                            <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                          </svg>
                        </div>
                        <div>{el.averageRating}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
