import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCYGxiVZPyJZ9JidypchCKdC_6pa-aFgEo",
  authDomain: "tmull-mongolia.firebaseapp.com",
  projectId: "tmull-mongolia",
  storageBucket: "tmull-mongolia.appspot.com",
  messagingSenderId: "189130868504",
  appId: "1:189130868504:web:a484d2633362579c00c06d",
  measurementId: "G-TR9017MT7B",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
const analytics = getAnalytics(app);
