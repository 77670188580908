import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import Comment from "./comment";
import { useState, useEffect, useRef } from "react";
// import mhz from '../assets/bid.png';
import gh from "../assets/godhand.jpg";
// import nova from '../assets/nova.png';
// import erdem from '../assets/erdem.svg';
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
// import topik from '../assets/topik.png';
import { useContext } from "react";
import axios from "axios";
import datas from "../assets/data.svg";

export const School = () => {
  const mergejil = [];
  const [ratings, setRatings] = useState(false);
  const [elselt, setElselt] = useState(false);
  const [tulbur, setTulbur] = useState(false);
  const [hutulbur, setHutulbur] = useState(false);
  const [suragchid, setSuragchid] = useState(false);
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const bairshil = location.pathname;
  const [rating, setRating] = useState(0);

  const handleRatingChange = async (e) => {
    if (localStorage.getItem("user")) {
      try {
        const response = await axios({
          url: `https://tmull-be.vercel.app/sch/${bairshil.split("/")[3]}/rate`,
          method: "POST",
          data: {
            userId: localStorage.getItem("user"), // Assuming this is the logged-in user's ID
            rating: Number(e.target.value), // Ensure the rating is a number
          },
        });

        console.log(response?.data);
        setRating(response?.data?.post?.averageRating);
      } catch (error) {
        console.error("Error submitting rating:", error);
      }
    } else {
      window.location.href = "/nevtreh"; // Redirect to login if no user
    }
  };

  useEffect(() => {
    document.title = "Tmull ・ Harvard University";
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", datas);
    const dataRetriever = async () => {
      try {
        const response = await axios.get(
          `https://tmull-be.vercel.app/sch/${bairshil.split("/")[3]}`
        );

        if (response.status) {
          console.log(response?.data);
          setData(response?.data?.data);
          setLoad(true);
          setRating(response?.data?.data?.averageRating);
        }
      } catch (error) {
        console.error("Error retrieving data:", error);
        window.location.href = "/";
      }
    };

    dataRetriever();
  }, []);
  return (
    <div>
      <div></div>
      {load ? (
        <div className="mt-24 lg:mt-28 dark:text-white font-semibold">
          <div className="w-full flex justify-center items-centers flex-col">
            <div className="group/item w-full h-96">
              <div className="border-2 dark:border-neutral-900 rounded-3xl overflow-hidden transition-all ease-in-out duration-700 delay-0 w-full h-96">
                <div className="h-96 flex flex-col cursor-pointer justify-center items-center">
                  <div className="relative h-96 flex w-full">
                    <img
                      className="absolute h-96 w-full object-cover group-hover/item:brightness-105 transition-all ease-in-out duration-1000 delay-0"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/sch%2F" +
                        data.img
                      }
                      alt=""
                    />
                    <div className="bg-gradient-to-t from-[#000000] to-[#00000000] h-[40%] w-full absolute bottom-0  group-hover/item:opacity-80 opacity-0 transition-all items-center ease-in-out duration-1000 delay-0 "></div>

                    <div className="bg-gradient-to-b from-[#000000] to-[#00000000] h-[40%] w-full absolute group-hover/item:opacity-0 opacity-80 transition-all items-center ease-in-out duration-1000 delay-0 "></div>

                    <div className="text-white text-xl md:text-2xl lg:text-3xl flex flex-col w-full justify-between transition-all items-center ease-in-out duration-1000 delay-0 transform group-hover/item:mt-72  md:p-6 p-8">
                      <div className="flex flex-row w-full justify-between">
                        <div className="w-full flex flex-row gap-2 justify-center items-center lg:h-16">
                          <img
                            className="rounded-lg object-cover bg-white lg:h-8 h-6"
                            src={
                              "https://firebasestorage.googleapis.com/v0/b/tmull-mongolia.appspot.com/o/sch%2F" +
                              data.logo
                            }
                            alt=""
                          />
                          <a
                            href="https://www.mit.edu/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {data.Uname}
                          </a>
                        </div>
                      </div>
                      <div className="backdrop-blur-xl px-2 rounded-lg mt-8 transition-all ease-in-out duration-1000 delay-0 transform md:text-lg text-base drop-shadow-md bg-[#0000005b]">
                        RD: {data.appl[3].RD[0].deadline}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center gap-2 mt-2">
            <div className="lg:text-lg lg:mt-1">{data.Uname}-г үнэлнэ үү</div>
            <div className="flex flex-row">
              <div className="text-sm flex items-center flex-row gap-1 justify-center overflow-visible group/item">
                {/* <svg
              className="h-6 xl:h-8"
              viewBox="0 0 18 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.66789 1.84887L1.66789 1.84887C3.22507 0.291692 5.74975 0.291692 7.30693 1.84887L7.66048 1.49532L7.30693 1.84887L8.21331 2.75525L8.56686 3.1088L8.92041 2.75526L10.0078 1.66789L10.0078 1.66788C11.5649 0.110707 14.0897 0.110707 15.6468 1.66788L15.6468 1.66789C17.204 3.22508 17.204 5.74974 15.6468 7.30693L15.6468 7.30694L9.1424 13.8114C9.1001 13.8537 9.05274 13.8864 9.00245 13.9102L8.92938 13.9448L8.87093 14.0006C8.67533 14.1875 8.36482 14.1848 8.17232 13.9924C8.17231 13.9924 8.1723 13.9924 8.17229 13.9924L1.66789 7.48792L1.31995 7.83586L1.66789 7.48792C0.110705 5.93074 0.110704 3.40605 1.66789 1.84887Z"
                className="fill-white stroke-black dark:fill-[#00000000] dark:stroke-neutral-400"
                stroke-width="1.3"
              />
            </svg> */}
                <div class="flex flex-row-reverse justify-end items-center group-hover/item:hidden">
                  <input
                    id="hs-ratings-readonly-5"
                    onChange={handleRatingChange}
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="5"
                    checked={rating === 5}
                  />
                  <div
                    for="hs-ratings-readonly-5"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                  <input
                    id="hs-ratings-readonly-4"
                    onChange={handleRatingChange}
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="4"
                    checked={rating === 4}
                  />
                  <div
                    for="hs-ratings-readonly-4"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                  <input
                    id="hs-ratings-readonly-3"
                    onChange={handleRatingChange}
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="3"
                    checked={rating === 3}
                  />
                  <div
                    for="hs-ratings-readonly-3"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                  <input
                    id="hs-ratings-readonly-2"
                    onChange={handleRatingChange}
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="2"
                    checked={rating === 2}
                  />
                  <div
                    for="hs-ratings-readonly-2"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                  <input
                    id="hs-ratings-readonly-1"
                    onChange={handleRatingChange}
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="1"
                    checked={rating === 1}
                  />
                  <div
                    for="hs-ratings-readonly-1"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                </div>
                <div class=" flex-row-reverse justify-end items-center hidden group-hover/item:flex">
                  <input
                    id="hs-ratings-readonly-5"
                    onChange={handleRatingChange}
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="5"
                  />
                  <div
                    for="hs-ratings-readonly-5"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                  <input
                    id="hs-ratings-readonly-4"
                    onChange={handleRatingChange}
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="4"
                  />
                  <div
                    for="hs-ratings-readonly-4"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                  <input
                    id="hs-ratings-readonly-3"
                    onChange={handleRatingChange}
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="3"
                  />
                  <div
                    for="hs-ratings-readonly-3"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                  <input
                    id="hs-ratings-readonly-2"
                    onChange={handleRatingChange}
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="2"
                  />
                  <div
                    for="hs-ratings-readonly-2"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                  <input
                    id="hs-ratings-readonly-1"
                    onChange={handleRatingChange}
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="1"
                  />
                  <div
                    for="hs-ratings-readonly-1"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                </div>
                <div>{rating}</div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between mt-4">
            <div className="text-2xl xl:text-3xl 2xl:text-3xl transition-all ease-in-out duration-300 delay-0 transform">
              Товч танилцуулга
            </div>
            <div className="lg:text-lg xl:text-xl transition-all ease-in-out duration-300 delay-0 transform opacity-90">
              {data.det}
            </div>
          </div>
          <div className="flex flex-wrap gap-2 mt-2">
            <div>Энэ сургуулийн вэбсайт руу зочлох:</div>

            <a
              href={data.link}
              target="_blank"
              className="hover:text-[#307000]"
              rel="noopener noreferrer"
            >
              {data.link}
            </a>
          </div>
          <div className="mt-8">
            <div className="text-2xl xl:text-3xl 2xl:text-3xl transition-all ease-in-out duration-300 delay-0 transform">
              Дэлгэрэнгүй мэдээлэл
            </div>
            <div class="flex flex-col sm:inline-flex sm:flex-row rounded-lg shadow-sm lg:mt-6 mt-2 overflow-x-auto w-full lg:w-auto">
              <button
                type="button"
                class="bg-[#037F00] text-white py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm focus:z-10 border border-gray-200 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              >
                Бүгд
              </button>
              <button
                type="button"
                class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              >
                Рейтинг
              </button>
              <button
                type="button"
                class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              >
                Сурагчид
              </button>
              <button
                type="button"
                class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              >
                Шаардлага
              </button>
              <button
                type="button"
                class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              >
                Элсэлт
              </button>
              <button
                type="button"
                class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              >
                Төлбөр
              </button>
              <button
                type="button"
                class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              >
                Хөтөлбөр
              </button>
              <button
                type="button"
                class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              >
                Онцлог
              </button>
              <button
                type="button"
                class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              >
                Цаг агаар
              </button>
              <button
                type="button"
                class="py-3 px-4 inline-flex items-center gap-x-2 -mt-px -ms-px first:rounded-t-md last:rounded-b-md sm:first:rounded-s-md sm:mt-0 sm:first:ms-0 sm:first:rounded-se-none sm:last:rounded-es-none sm:last:rounded-e-md text-sm  focus:z-10 border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              >
                Бичлэг
              </button>
            </div>
          </div>
          <div className="mt-8 w-full">
            <div className="text-2xl xl:text-3xl 2xl:text-3xl transition-all ease-in-out duration-300 delay-0 transform">
              Рейтинг
            </div>
            <div className="w-full flex justify-center">
              <div className="gap-2 w-full flex flex-col lg:text-lg justify-center items-center">
                {data.rate.map((el) => {
                  return (
                    <div className="flex flex-row gap-1">
                      <div>{el.name}</div>
                      <div className="text-sm px-1 xl:p-1 bg-[#98B851] rounded-lg text-white flex justify-center items-center">
                        #{el.place}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mt-8">
            <div className="text-2xl xl:text-3xl 2xl:text-3xl transition-all ease-in-out duration-300 delay-0 transform">
              Сурагчид
            </div>
            <div className="text-gray-700 dark:text-gray-400">
              2023 оны мэдээлэл болно
            </div>
            <div className="flex flex-wrap justify-between lg:justify-evenly mt-2 gap-x-4">
              <div className="text-lg xl:text-xl 2xl:text-2xl mt-2">
                Нийт {data.s[0].All} оюутантай
              </div>
              <div>
                <div className="text-lg xl:text-xl 2xl:text-2xl mt-2">
                  Тэтгэлэг хүлээн авагчид:
                </div>
                <div className="text-md lg:text-lg">{data.s[0].gra}</div>
              </div>
              <div>
                <div className="text-lg xl:text-xl 2xl:text-2xl mt-2">
                  Төгсөгчдийн хувь:
                </div>
                <div className="text-md lg:text-lg">{data.s[0].sch}</div>
              </div>
              <div>
                <div className="text-lg xl:text-xl 2xl:text-2xl mt-2">
                  Гадаад сурагчдын хувь:
                </div>
                <div className="text-md lg:text-lg">{data.s[0].istu}</div>
              </div>
            </div>
          </div>

          <div className="mt-8 flex flex-col items-center justify-center">
            <div className="text-2xl xl:text-3xl 2xl:text-3xl transition-all ease-in-out duration-300 delay-0 transform w-full">
              Шаардлага
            </div>
            <table className="border-collapse table-auto text-sm md:text-base mt-4 w-full md:w-[90%] lg:w-[80%] xl:w-[60%]">
              <caption className="text-gray-700 dark:text-gray-400 pt-3 caption-bottom">
                2022-2023 оны хоорондох дундаж дүнгийн мэдээлэл гэдгийг анхаарна
                уу
              </caption>
              <thead>
                <tr>
                  <th className="border p-4 pl-8  pt-3 pb-3 text-left">
                    Төрөл
                  </th>
                  <th className="border p-4 pr-8 pt-3 pb-3 text-left">
                    Дундаж оноо (Бакалаврын)
                  </th>
                  <th className="border p-4 pr-8 pt-3 pb-3 text-left">
                    Дундаж оноо (Магистрын)
                  </th>
                </tr>
              </thead>
              <tbody className="bg-[#027f0065]">
                {data.reqq.map((el) => {
                  return (
                    <tr>
                      <td className="border border-slate-200 uppercase p-4 pl-8 ">
                        {Object.keys(el)[0]}
                      </td>
                      <td className="border border-slate-200 p-4 pr-8 ">
                        {Object.values(el)[0].b}
                      </td>
                      <td className="border border-slate-200 p-4 pr-8 ">
                        {Object.values(el)[0].m}
                      </td>
                    </tr>
                  );
                })}
                {/* {data.reqq[0].m.map(el => {
									return (
										<td className="border border-slate-200 p-4 pr-8 ">
											{Object.values(el)}
										</td>
									);
								})} */}
                {/* {console.log(data.features)} */}
              </tbody>
            </table>
          </div>
          <div className="mt-8">
            <div className="text-2xl xl:text-3xl 2xl:text-3xl transition-all ease-in-out duration-300 delay-0 transform">
              Элсэлт
            </div>
            <div className="w-full overflow-x-auto">
              <table className="border-collapse table-auto w-full mt-2">
                <thead>
                  <tr>
                    <th className="border-b p-4 pt-0 pb-3 first:pl-8 last:pr-8 text-[#037F00] text-left">
                      Төрөл
                    </th>
                    {data.appl[0].EA[0].deadline === "" ? (
                      <></>
                    ) : (
                      <th className="border-b p-4 pt-0 pb-3 first:pl-8 last:pr-8 text-[#037F00] text-left">
                        Эртэч арга хэмжээ (EA)
                      </th>
                    )}
                    {data.appl[1].EI[0].deadline === "" ? (
                      <></>
                    ) : (
                      <th className="border-b p-4 pt-0 pb-3 first:pl-8 last:pr-8 text-[#037F00] text-left">
                        Эртэч Шийдвэр I (EDI)
                      </th>
                    )}
                    {data.appl[2].EII[0].deadline === "" ? (
                      <></>
                    ) : (
                      <th className="border-b p-4 pt-0 pb-3 first:pl-8 last:pr-8 text-[#037F00] text-left">
                        Эртэч Шийдвэр II (EDII)
                      </th>
                    )}
                    {data.appl[3].RD[0].deadline === "" ? (
                      <></>
                    ) : (
                      <th className="border-b p-4 pt-0 pb-3 first:pl-8 last:pr-8 text-[#037F00] text-left">
                        Жирийн Шийдвэр (RD)
                      </th>
                    )}
                    {(() => {
                      try {
                        {
                          console.log(data.appl[6].LA[0].deadline);
                        }
                        if (data.appl[6].LA[0].deadline !== "") {
                          return (
                            <>
                              <th className="border-b p-4 pt-0 pb-3 first:pl-8 last:pr-8 text-[#037F00] text-left">
                                Хожуу элсэлт (LA)
                              </th>
                            </>
                          );
                        }
                      } catch (error) {
                        console.error("Error accessing data:", error);
                        return null;
                      }
                    })()}
                    {(() => {
                      try {
                        if (
                          data.appl[4].TAI[0].deadline !== "" &&
                          data.appl[5].TAII[0].deadline !== ""
                        ) {
                          return (
                            <>
                              <th className="border-b p-4 pt-0 pb-3 first:pl-8 last:pr-8 text-[#037F00] text-left">
                                Шилжих элсэлт I (TAI)
                              </th>
                              <th className="border-b p-4 pt-0 pb-3 first:pl-8 last:pr-8 text-[#037F00] text-left">
                                Шилжих элсэлт II (TAII)
                              </th>
                            </>
                          );
                        }
                      } catch (error) {
                        console.error("Error accessing data:", error);
                        return null;
                      }
                    })()}
                  </tr>
                </thead>
                <tbody className="bg-[#027f0065]">
                  <tr>
                    <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                      Дуусах хугацаа
                    </td>
                    {data.appl[0].EA[0].deadline === "" ? (
                      <></>
                    ) : (
                      <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                        {data.appl[0].EA[0].deadline}
                      </td>
                    )}
                    {data.appl[1].EI[0].deadline === "" ? (
                      <></>
                    ) : (
                      <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                        {data.appl[1].EI[0].deadline}
                      </td>
                    )}
                    {data.appl[2].EII[0].deadline === "" ? (
                      <></>
                    ) : (
                      <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                        {data.appl[2].EII[0].deadline}
                      </td>
                    )}
                    {data.appl[3].RD[0].deadline === "" ? (
                      <></>
                    ) : (
                      <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                        {data.appl[3].RD[0].deadline}
                      </td>
                    )}
                    {(() => {
                      try {
                        if (
                          data.appl[4].TAI[0].deadline !== "" &&
                          data.appl[5].TAII[0].deadline !== ""
                        ) {
                          return (
                            <>
                              {" "}
                              <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                                {data.appl[4].TAI[0].deadline}
                              </td>
                              <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                                {data.appl[5].TAII[0].deadline}
                              </td>
                            </>
                          );
                        }
                      } catch (error) {
                        console.error("Error accessing data:", error);
                        return null;
                      }
                    })()}
                    {(() => {
                      try {
                        if (data.appl[6].LA[0].deadline !== "") {
                          return (
                            <>
                              <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                                {data.appl[6].LA[0].deadline}
                              </td>
                            </>
                          );
                        }
                      } catch (error) {
                        console.error("Error accessing data:", error);
                        return null;
                      }
                    })()}
                  </tr>
                  {data.appl[0].EA[0].interview !== "" ||
                  data.appl[1].EI[0].interview !== "" ||
                  data.appl[2].EII[0].interview !== "" ||
                  data.appl[3].RD[0].interview !== "" ||
                  data.appl[4].TAI[0].interview !== "" ||
                  data.appl[5].TAII[0].interview !== "" ||
                  data.appl[6].LA[0].interview !== "" ? (
                    <tr>
                      <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                        Ярилцлага
                      </td>
                      {data.appl[0].EA[0].deadline === "" ? (
                        <></>
                      ) : (
                        <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                          {data.appl[0].EA[0].interview}
                        </td>
                      )}
                      {data.appl[1].EI[0].deadline === "" ? (
                        <></>
                      ) : (
                        <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                          {data.appl[1].EI[0].interview}
                        </td>
                      )}
                      {data.appl[2].EII[0].deadline === "" ? (
                        <></>
                      ) : (
                        <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                          {data.appl[2].EII[0].interview}
                        </td>
                      )}
                      {data.appl[3].RD[0].deadline === "" ? (
                        <></>
                      ) : (
                        <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                          {data.appl[3].RD[0].interview}
                        </td>
                      )}
                      {(() => {
                        try {
                          if (
                            data.appl[4].TAI[0].interview !== "" &&
                            data.appl[5].TAII[0].interview !== ""
                          ) {
                            return (
                              <>
                                {" "}
                                <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                                  {data.appl[4].TAI[0].interview}
                                </td>
                                <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                                  {data.appl[5].TAII[0].interview}
                                </td>
                              </>
                            );
                          }
                        } catch (error) {
                          console.error("Error accessing data:", error);
                          return null;
                        }
                      })()}
                      {(() => {
                        try {
                          if (data.appl[6].LA[0].interview !== "") {
                            return (
                              <>
                                <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                                  {data.appl[6].LA[0].interview}
                                </td>
                              </>
                            );
                          }
                        } catch (error) {
                          console.error("Error accessing data:", error);
                          return null;
                        }
                      })()}
                    </tr>
                  ) : (
                    <></>
                  )}
                  {data.appl[0].EA[0].result !== "" ||
                  data.appl[1].EI[0].result !== "" ||
                  data.appl[2].EII[0].result !== "" ||
                  data.appl[3].RD[0].result !== "" ||
                  data.appl[4].TAI[0].result !== "" ||
                  data.appl[5].TAII[0].result !== "" ||
                  data.appl[6].LA[0].result !== "" ? (
                    <tr>
                      <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                        Хариу
                      </td>
                      {data.appl[0].EA[0].result === "" ? (
                        <></>
                      ) : (
                        <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                          {data.appl[0].EA[0].result}
                        </td>
                      )}
                      {data.appl[1].EI[0].result === "" ? (
                        <></>
                      ) : (
                        <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                          {data.appl[1].EI[0].result}
                        </td>
                      )}
                      {data.appl[2].EII[0].result === "" ? (
                        <></>
                      ) : (
                        <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                          {data.appl[2].EII[0].result}
                        </td>
                      )}
                      {data.appl[3].RD[0].result === "" ? (
                        <></>
                      ) : (
                        <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                          {data.appl[3].RD[0].result}
                        </td>
                      )}
                      {(() => {
                        try {
                          if (
                            data.appl[4].TAI[0].deadline !== "" &&
                            data.appl[5].TAII[0].deadline !== ""
                          ) {
                            return (
                              <>
                                <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                                  {data.appl[4].TAI[0].result}
                                </td>
                                <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                                  {data.appl[5].TAII[0].result}
                                </td>
                              </>
                            );
                          }
                        } catch (error) {
                          console.error("Error accessing data:", error);
                          return null;
                        }
                      })()}
                      {(() => {
                        try {
                          if (data.appl[6].LA[0].deadline !== "") {
                            return (
                              <>
                                <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                                  {data.appl[6].LA[0].result}
                                </td>
                              </>
                            );
                          }
                        } catch (error) {
                          console.error("Error accessing data:", error);
                          return null;
                        }
                      })()}
                    </tr>
                  ) : (
                    <></>
                  )}

                  {data.appl[0].EA[0].css !== "" ||
                  data.appl[1].EI[0].css !== "" ||
                  data.appl[2].EII[0].css !== "" ||
                  data.appl[3].RD[0].css !== "" ||
                  data.appl[4].TAI[0].css !== "" ||
                  data.appl[5].TAII[0].css !== "" ||
                  data.appl[6].LA[0].css !== "" ? (
                    <tr>
                      <td className="border-b border-slate-200 p-4 first:pl-8 last:pr-8">
                        CSS дуусах
                      </td>
                      {data.appl[0].EA[0].css === "" ? (
                        <></>
                      ) : (
                        <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                          {data.appl[0].EA[0].css}
                        </td>
                      )}
                      {data.appl[1].EI[0].css === "" ? (
                        <></>
                      ) : (
                        <td className="border-b border-slate-200 p-4 first:pl-8 last:pr-8">
                          {data.appl[1].EI[0].css}
                        </td>
                      )}
                      {data.appl[2].EII[0].css === "" ? (
                        <></>
                      ) : (
                        <td className="border-b border-slate-200 p-4 first:pl-8 last:pr-8">
                          {data.appl[2].EII[0].css}
                        </td>
                      )}
                      {data.appl[3].RD[0].css === "" ? (
                        <></>
                      ) : (
                        <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                          {data.appl[3].RD[0].css}
                        </td>
                      )}
                      {(() => {
                        try {
                          if (
                            data.appl[4].TAI[0].deadline !== "" &&
                            data.appl[5].TAII[0].deadline !== ""
                          ) {
                            return (
                              <>
                                <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                                  {data.appl[4].TAI[0].css}
                                </td>
                                <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                                  {data.appl[5].TAII[0].css}
                                </td>
                              </>
                            );
                          }
                        } catch (error) {
                          console.error("Error accessing data:", error);
                          return null;
                        }
                      })()}
                      {(() => {
                        try {
                          if (data.appl[6].LA[0].css !== "") {
                            return (
                              <>
                                <td className="border-b border-slate-100 p-4 first:pl-8 last:pr-8">
                                  {data.appl[6].LA[0].css}
                                </td>
                              </>
                            );
                          }
                        } catch (error) {
                          console.error("Error accessing data:", error);
                          return null;
                        }
                      })()}
                    </tr>
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-8">
            <div className="text-2xl xl:text-3xl 2xl:text-3xl transition-all ease-in-out duration-300 delay-0 transform">
              Зардал
            </div>
            <div className="text-gray-700 dark:text-gray-400">
              2022-2023 оны хоорондох дундаж дүнгийн мэдээлэл гэдгийг анхаарна
              уу
            </div>
            <div className="mt-2">
              <div className="flex border-2 rounded-lg w-full">
                <div
                  style={{
                    width: `${Math.round(
                      (parseInt(data.exp[0].edu.replace(/[^\d]/g, ""), 10) /
                        (parseInt(data.exp[0].edu.replace(/[^\d]/g, ""), 10) +
                          parseInt(
                            data.exp[0].liv.replace(/[^\d]/g, ""),
                            10
                          ))) *
                        100
                    )}%`,
                  }}
                  className=" px-4 py-2 bg-[#027f0065] rounded-l-lg shadow-lg text-center"
                >
                  {Math.round(
                    (parseInt(data.exp[0].edu.replace(/[^\d]/g, ""), 10) /
                      (parseInt(data.exp[0].edu.replace(/[^\d]/g, ""), 10) +
                        parseInt(data.exp[0].liv.replace(/[^\d]/g, ""), 10))) *
                      100
                  )}
                  %<div className="">сургалтын төлбөр</div>
                </div>
                <div
                  style={{
                    width: `${
                      100 -
                      Math.round(
                        (parseInt(data.exp[0].edu.replace(/[^\d]/g, ""), 10) /
                          (parseInt(data.exp[0].edu.replace(/[^\d]/g, ""), 10) +
                            parseInt(
                              data.exp[0].liv.replace(/[^\d]/g, ""),
                              10
                            ))) *
                          100
                      )
                    }%`,
                  }}
                  className="px-4 py-2 bg-white dark:text-black rounded-r-lg shadow-lg text-center"
                >
                  {100 -
                    Math.round(
                      (parseInt(data.exp[0].edu.replace(/[^\d]/g, ""), 10) /
                        (parseInt(data.exp[0].edu.replace(/[^\d]/g, ""), 10) +
                          parseInt(
                            data.exp[0].liv.replace(/[^\d]/g, ""),
                            10
                          ))) *
                        100
                    )}
                  %<div>бусад</div>
                </div>
              </div>
            </div>
            <div className="flex lg:flex-wrap justify-between lg:justify-evenly mt-2">
              <div>
                <div className="text-lg xl:text-xl 2xl:text-2xl mt-2">
                  Сургалтын төлбөр:
                </div>
                <div className="text-md lg:text-lg">{data.exp[0].edu}</div>
              </div>
              <div>
                <div className="text-lg xl:text-xl 2xl:text-2xl mt-2">
                  Амжиргааны зардал (Жилийн):
                </div>
                <div className="text-md lg:text-lg">{data.exp[0].liv}</div>
              </div>
            </div>
          </div>
          <div className="mt-8">
            <div className="text-2xl xl:text-3xl 2xl:text-3xl transition-all ease-in-out duration-300 delay-0 transform">
              Хөтөлбөр
            </div>
            <div className="text-gray-700 dark:text-gray-400">
              2023 оны мэдээлэл болно
            </div>
            <div className="flex flex-col lg:flex-row justify-between xl:justify-evenly gap-4">
              <div className="flex flex-col justify-center items-center text-md xl:text-lg 2xl:text-xl">
                <div className="mt-2 text-lg xl:text-xl 2xl:text-2xl">
                  Бакалаврын хөтөлбөрүүд
                </div>
                <div className="gap-1 flex flex-col">
                  {data.ed[0].b.map((el) => {
                    return (
                      <div className="flex flex-row w-full gap-1 justify-center items-center">
                        <div>{el.n}</div>
                        <div className="text-sm px-1 xl:p-1 bg-[#98B851] rounded-lg text-white flex justify-center items-center">
                          {el.r}%
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {data.ed[0].m.length === 0 ? (
                <></>
              ) : (
                <div className="flex flex-col justify-center items-center text-md xl:text-lg 2xl:text-xl">
                  <div className="mt-2 text-lg xl:text-xl 2xl:text-2xl">
                    Магистрын хөтөлбөрүүд
                  </div>

                  <div className="gap-1 flex flex-col lg:text-lg">
                    {data.ed[0].m.map((el) => {
                      return (
                        <div className="flex flex-row w-full gap-1 justify-center items-center">
                          <div>{el.n}</div>
                          <div className="text-sm px-1 xl:p-1 bg-[#98B851] rounded-lg text-white flex justify-center items-center">
                            {el.r}%
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="mt-8 flex flex-col items-center justify-center">
            <div className="text-2xl xl:text-3xl 2xl:text-3xl transition-all ease-in-out duration-300 delay-0 transform w-full">
              Онцлог
            </div>
            <table className="border-collapse table-auto text-sm md:text-base mt-4 w-full md:w-[90%] lg:w-[80%] xl:w-[60%]">
              <tbody>
                {data.features.map((el) => {
                  return (
                    <tr>
                      <td className="uppercase p-1 lg:p-2">{el}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="mt-8">
            <div className="text-2xl xl:text-3xl 2xl:text-3xl transition-all ease-in-out duration-300 delay-0 transform">
              Цаг агаар
            </div>
            <div className="flex flex-wrap justify-between lg:justify-evenly mt-2 gap-x-4">
              <table className="border-collapse table-auto text-sm md:text-base mt-4 w-full md:w-[90%] lg:w-[80%] xl:w-[60%]">
                <thead>
                  <tr>
                    {console.log(data.weather)}
                    <th className="border p-4 pt-3 pb-3 text-left">Улирал</th>
                    <th className="border p-4 pt-3 pb-3 text-left">Сар</th>
                    <th className="border p-4 pt-3 pb-3 text-left">
                      Температур
                    </th>
                    <th className="border p-4 pt-3 pb-3 text-left">
                      Цаг агаар
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-[#027f0065]">
                  {data.weather.map((el) => {
                    return (
                      <tr>
                        <td className="border border-slate-200 uppercase p-4">
                          {el.season}
                        </td>
                        <td className="border border-slate-200 p-4">
                          {el.month}
                        </td>
                        <td className="border border-slate-200 p-4">
                          {el.temp}
                        </td>
                        <td className="border border-slate-200 p-4">
                          {el.weat}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="mt-8 flex flex-col items-center justify-center">
            <div className="text-2xl xl:text-3xl 2xl:text-3xl transition-all ease-in-out duration-300 delay-0 transform w-full">
              Бичлэг
            </div>
            <table className="border-collapse table-auto text-sm md:text-base mt-4 w-full md:w-[90%] lg:w-[80%] xl:w-[60%]">
              <tbody>
                {data.video.map((el) => {
                  return (
                    <tr>
                      <td className="uppercase p-1 lg:p-2 border border-slate-200 bg-[#027f009b]">
                        {Object.keys(el)[0]}
                      </td>
                      <td className="uppercase p-1 lg:p-2 underline decoration-[#307000] decoration-2 border border-slate-200">
                        <a
                          target="_blank"
                          className="hover:text-[#307000]"
                          rel="noopener noreferrer"
                          href={Object.values(el)[0]}
                        >
                          {Object.values(el)[0]}
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Comment id={data._id} />
        </div>
      ) : (
        <div className="mt-24 lg:mt-28 dark:text-white font-semibold animate-pulse">
          <div className="w-full flex justify-center items-centers flex-col">
            <div className="group/item w-full h-96">
              <div className="border-2 dark:border-neutral-900 rounded-3xl overflow-hidden transition-all ease-in-out duration-700 delay-0 w-full h-96">
                <div className="h-96 flex flex-col cursor-pointer justify-center items-center">
                  <div className="relative h-96 flex w-full bg-black/10"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center gap-2 mt-2">
            <div className="lg:text-lg lg:mt-1 bg-black/10 h-5 w-16"></div>
            <div className="flex flex-row">
              <div className="text-sm flex items-center flex-row gap-1 justify-center overflow-visible group/item">
                {/* <svg
              className="h-6 xl:h-8"
              viewBox="0 0 18 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.66789 1.84887L1.66789 1.84887C3.22507 0.291692 5.74975 0.291692 7.30693 1.84887L7.66048 1.49532L7.30693 1.84887L8.21331 2.75525L8.56686 3.1088L8.92041 2.75526L10.0078 1.66789L10.0078 1.66788C11.5649 0.110707 14.0897 0.110707 15.6468 1.66788L15.6468 1.66789C17.204 3.22508 17.204 5.74974 15.6468 7.30693L15.6468 7.30694L9.1424 13.8114C9.1001 13.8537 9.05274 13.8864 9.00245 13.9102L8.92938 13.9448L8.87093 14.0006C8.67533 14.1875 8.36482 14.1848 8.17232 13.9924C8.17231 13.9924 8.1723 13.9924 8.17229 13.9924L1.66789 7.48792L1.31995 7.83586L1.66789 7.48792C0.110705 5.93074 0.110704 3.40605 1.66789 1.84887Z"
                className="fill-white stroke-black dark:fill-[#00000000] dark:stroke-neutral-400"
                stroke-width="1.3"
              />
            </svg> */}

                <div class=" flex-row-reverse justify-end items-center hidden group-hover/item:flex">
                  <input
                    id="hs-ratings-readonly-5"
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="5"
                  />
                  <div
                    for="hs-ratings-readonly-5"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                  <input
                    id="hs-ratings-readonly-4"
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="4"
                  />
                  <div
                    for="hs-ratings-readonly-4"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                  <input
                    id="hs-ratings-readonly-3"
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="3"
                  />
                  <div
                    for="hs-ratings-readonly-3"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                  <input
                    id="hs-ratings-readonly-2"
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="2"
                  />
                  <div
                    for="hs-ratings-readonly-2"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                  <input
                    id="hs-ratings-readonly-1"
                    type="radio"
                    class="peer -ms-5 size-5 bg-transparent border-0 text-transparent cursor-pointer appearance-none checked:bg-none focus:bg-none focus:ring-0 focus:ring-offset-0"
                    name="hs-ratings-readonly"
                    value="1"
                  />
                  <div
                    for="hs-ratings-readonly-1"
                    class="peer-checked:text-yellow-400 text-gray-300 pointer-events-none dark:peer-checked:text-yellow-600 dark:text-neutral-600"
                  >
                    <svg
                      class="flex-shrink-0 size-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-2 mt-2 h-5 w-20"></div>
          <div className="mt-8 w-full h-16 bg-black/10"></div>
        </div>
      )}
    </div>
  );
};
