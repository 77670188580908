import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { useState, useEffect, useRef } from "react";
// import tmll from '../assets/tmll.svg';
// import available from '../assets/bolomjtoi.svg';
// import unavailable from '../assets/bolomjgui.svg';
// import availableb from '../assets/bolomjtoib.svg';
// import unavailableb from '../assets/bolomjguib.svg';
// import sbdbg from '../assets/sbd.png';
// import sat from '../assets/sat.png';
// import ielts from '../assets/ielts.png';
import { useContext } from "react";
import axios from "axios";
export const SignUp = () => {
  const [start, setStart] = useState("");
  const [alert, setAlert] = useState("");
  const [load, setLoad] = useState(false);
  const [toggle1, setToggle1] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [position, setPosition] = useState(0);
  const [emailValue, setEmailValue] = useState("");
  const [passValue, setPassValue] = useState("");
  const [passValue2, setPassValue2] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [ageValue, setAgeValue] = useState("");
  const [usernameValue, setUsernameValue] = useState("");
  const [lastnameValue, setLastnameValue] = useState("");
  const [selectedGender, setSelectedGender] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [orgValue, setOrgValue] = useState("");
  const [securityq1, setSecurityq1] = useState("");
  const [securityq2, setSecurityq2] = useState("");
  const [securityq3, setSecurityq3] = useState("");
  const [accountTypeValue, setAccountTypeValue] = useState(3);
  const [checked, setChecked] = useState(false);
  console.log(accountTypeValue);
  const [mode, setMode] = useState(() => {
    if (localStorage.getItem("dark-mode") === "false") {
      return false;
    } else {
      return true;
    }
  });
  useEffect(() => {
    if (localStorage.getItem("dark-mode") === "false") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("dark-mode", "false");
    }
    if (localStorage.getItem("dark-mode") === "true") {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("dark-mode", "true");
    }
  }, []);
  const navigate = useNavigate();
  console.log({
    username: usernameValue,
    name: nameValue,
    lastname: lastnameValue,
    age: ageValue,
    gender: selectedGender,
    phone: phoneValue,
    org: orgValue,
    securityq: [{ 1: securityq1 }, { 2: securityq2 }, { 3: securityq3 }],
    accountType:
      accountTypeValue === 0
        ? "child"
        : accountTypeValue === 1
        ? "young adult"
        : accountTypeValue === 2
        ? "caregivers"
        : "",
    settings: [
      { emailnotif: toggle1 },
      { privacy: toggle2 },
      { darkmode: localStorage.getItem("dark-mode") === true },
    ],
    password: passValue,
    email: emailValue,
  });
  const inputRefs = useRef([]);

  const handleSubmit = () => {
    const pinValues = inputRefs.current.map((input) => input.value);
    console.log("PIN:", pinValues.join(""));
  };
  const checkUser = () => {
    const user = localStorage.getItem("user");
    if (user) navigate("/");
  };
  const handleRadioChange = (event) => {
    setSelectedGender(event.target.value);
  };

  useEffect(() => {
    checkUser();
  }, []);
  const [blank1, setBlank1] = useState(
    "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[0%]"
  );
  const [blank2, setBlank2] = useState(
    "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[0%]"
  );
  const [blank3, setBlank3] = useState(
    "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[0%]"
  );
  // const dataRetriever = async () => {
  // 	const result = await axios.post('https://tmull-be.vercel.app/user/', {
  // 		email: emailValue,
  // 		password: passValue,
  // 	});
  // 	console.log(result);
  // 	navigate('/login');
  // };
  useEffect(() => {
    document.title = "Tmull ・ Нэвтрэх";
  }, []);
  useEffect(() => {
    if (position === 1) {
      setBlank1(
        "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[00%] transition-all ease-in-out duration-300 delay-0 transform"
      );
    } else if (position === 2) {
      setBlank1(
        "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[33%] transition-all ease-in-out duration-300 delay-0 transform"
      );
    } else if (position === 3) {
      setBlank1(
        "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[66%] transition-all ease-in-out duration-300 delay-0 transform"
      );
    } else {
      setBlank1(
        "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[100%] transition-all ease-in-out duration-300 delay-0 transform"
      );
    }
    if (position === 5) {
      setBlank2(
        "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[50%] transition-all ease-in-out duration-300 delay-0 transform"
      );
    } else if (position >= 6) {
      setBlank2(
        "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[100%] transition-all ease-in-out duration-300 delay-0 transform"
      );
    } else {
      setBlank2(
        "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[0%] transition-all ease-in-out duration-300 delay-0 transform"
      );
    }
    if (position === 7) {
      setBlank3(
        "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[50%] transition-all ease-in-out duration-300 delay-0 transform"
      );
    } else if (position >= 8) {
      setBlank3(
        "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[100%] transition-all ease-in-out duration-300 delay-0 transform"
      );
    } else {
      setBlank3(
        "bg-[#037F00] text-xs lg:text-sm leading-none py-1 text-center text-grey-darkest rounded w-[0%] transition-all ease-in-out duration-300 delay-0 transform"
      );
    }
  }, [position]);

  return (
    <div className="mt-24 lg:mt-28 font-semibold dark:text-white relative">
      <div className="w-full flex justify-end">
        {alert !== "" ? (
          <div
            id="dismiss-alert"
            class="hs-removing:translate-x-5 z-50 top-16 lg:top-24 lg:w-min fixed hs-removing:opacity-0 transition duration-300 border-red-200 bg-red-100 text-red-700 dark:bg-red-950 dark:border-red-900 dark:text-red-500 border text-sm rounded-lg p-4"
            role="alert"
          >
            <div class="flex">
              <div class="flex-shrink-0">
                <svg
                  class="flex-shrink-0 size-4 mt-0.5"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"></path>
                  <path d="m9 12 2 2 4-4"></path>
                </svg>
              </div>
              <div class="ms-2">
                <div class="text-sm font-medium w-max">{alert}</div>
              </div>
              <div class="ps-3 ms-auto">
                <div class="-mx-1.5 -my-1.5">
                  <button
                    type="button"
                    class="inline-flex rounded-lg p-1.5 text-red-700 hover:bg-red-300 dark:bg-transparent dark:hover:bg-red-800/50 dark:text-red-600"
                    onclick={() => {
                      setAlert("");
                    }}
                  >
                    <span class="sr-only">Dismiss</span>
                    <svg
                      class="flex-shrink-0 size-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M18 6 6 18"></path>
                      <path d="m6 6 12 12"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      {position === 0 ? (
        <div className="w-full h-[75vh] rounded-lg border-2 dark:border-[#00000000] flex justify-center items-center relative overflow-hidden">
          <button
            onClick={() => {
              setPosition(position + 1);
            }}
            class="peer text-xl lg:text-2xl xl:text-3xl px-8 bg-[#037F00] rounded-lg text-white py-3 group/item hover:text-[#037F00] hover:scale-110 hover:bg-white border-2 border-black transition-all ease-in-out duration-300 delay-0 transdiv z-10 drop-shadow-lg"
          >
            Эхлэх
          </button>
          <div className="w-full h-full flex justify-center items-center preview grayscale blur-sm peer-hover:blur-0 absolute peer-hover:grayscale-0 transition-all ease-in-out duration-700 delay-0 transdiv"></div>
        </div>
      ) : (
        <></>
      )}
      <form
        className={
          position > 0
            ? "flex flex-col gap-2 justify-center items-center "
            : "flex-col gap-2 justify-center items-center hidden"
        }
      >
        <div className="w-full lg:w-[90%] my-4 border-b-2 p-4">
          <div className="flex pb-8 lg:pb-6 gap-1">
            <div className="flex-1 flex flex-col justify-center items-center">
              <div
                className={
                  position === 1
                    ? "w-10 h-10 bg-white border-2 border-grey-300 mx-auto rounded-full text-lg flex items-center"
                    : "w-10 h-10 bg-[#037F00] mx-auto rounded-full text-lg flex items-center"
                }
              >
                <span className="text-center w-full">
                  <div
                    className={
                      position === 1
                        ? "text-grey-900 text-center w-full text-black"
                        : "w-full fill-current text-white"
                    }
                  >
                    1
                  </div>
                </span>
              </div>
              <div className="flex justify-center items-center">
                <div className="absolute text-xs lg:text-sm text-center mt-10 w-min lg:w-max">
                  Хувийн мэдээлэл
                </div>
              </div>
            </div>

            <div className="w-1/6 align-center items-center align-middle content-center flex">
              <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                <div className={blank1}></div>
              </div>
            </div>

            <div className="flex-1">
              <div
                className={
                  position <= 4
                    ? "w-10 h-10 bg-white border-2 border-grey-300 mx-auto rounded-full text-lg flex items-center"
                    : "w-10 h-10 bg-[#037F00] mx-auto rounded-full text-lg flex items-center"
                }
              >
                <span className="text-center w-full">
                  <div
                    className={
                      position <= 4
                        ? "text-grey-900 text-center w-full text-black"
                        : "w-full fill-current text-white"
                    }
                  >
                    2
                  </div>
                </span>
              </div>
              <div className="flex justify-center items-center">
                <div className="absolute text-xs lg:text-sm text-center mt-10 w-min lg:w-max">
                  Бүртгэлийн хамгаалалт
                </div>
              </div>
            </div>

            <div className="w-1/6 align-center items-center align-middle content-center flex">
              <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                <div className={blank2}></div>
              </div>
            </div>

            <div className="flex-1">
              <div
                className={
                  position <= 6
                    ? "w-10 h-10 bg-white border-2 border-grey-300 mx-auto rounded-full text-lg flex items-center"
                    : "w-10 h-10 bg-[#037F00] mx-auto rounded-full text-lg flex items-center"
                }
              >
                <span className="text-center w-full">
                  <div
                    className={
                      position <= 6
                        ? "text-grey-900 text-center w-full text-black"
                        : "w-full fill-current text-white"
                    }
                  >
                    3
                  </div>
                </span>
              </div>
              <div className="flex justify-center items-center">
                <div className="absolute text-xs lg:text-sm text-center mt-10 w-min lg:w-max">
                  Хэрэглэгчийн тохиргоо
                </div>
              </div>
            </div>

            <div className="w-1/6 align-center items-center align-middle content-center flex">
              <div className="w-full bg-gray-200 rounded items-center align-middle align-center flex-1">
                <div className={blank3}></div>
              </div>
            </div>

            <div className="flex-1">
              <div
                className={
                  position <= 7
                    ? "w-10 h-10 bg-white border-2 border-grey-300 mx-auto rounded-full text-lg flex items-center"
                    : "w-10 h-10 bg-[#037F00] mx-auto rounded-full text-lg flex items-center"
                }
              >
                <span className="text-center w-full">
                  <div
                    className={
                      position <= 7
                        ? "text-grey-900 text-center w-full text-black"
                        : "w-full fill-current text-white"
                    }
                  >
                    4
                  </div>
                </span>
              </div>
              <div className="flex justify-center items-center">
                <div className="absolute text-xs lg:text-sm text-center mt-10 w-min lg:w-max">
                  Хянах шалгах
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            position === 1 ? "w-full lg:w-[80%]" : "w-full lg:w-[80%] hidden"
          }
        >
          <div className="mb-6">
            <div>
              <div
                for="account_name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Бүртгэлийн нэр(бусад хүмүүс таныг олж таних нэр)
              </div>
              <input
                type="text"
                onChange={(e) => setUsernameValue(e.target.value)}
                id="account_name"
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="misheel_b"
                required
              />
            </div>
          </div>
          <div class="grid gap-6 mb-6 md:grid-cols-2 w-full">
            <div>
              <div
                for="first_name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Нэр
              </div>
              <input
                onChange={(e) => setNameValue(e.target.value)}
                type="text"
                id="first_name"
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Мишээл"
                required
              />
            </div>
            <div>
              <div
                for="last_name"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Овог
              </div>
              <input
                type="text"
                onChange={(e) => setLastnameValue(e.target.value)}
                id="last_name"
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Батэрдэнэ"
                required
              />
            </div>
            <div>
              <div
                for="age"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Нас
              </div>
              <input
                type="date"
                id="age"
                onChange={(e) => setAgeValue(e.target.value)}
                class="bg-gray-50 border-2 border-gray-500 dark:text-white dark:[color-scheme:dark] text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400"
                min="1900-01-01"
                max="2014-12-31"
                required
              />
            </div>
            <div>
              <div
                for="website"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Хүйс
              </div>
              <div className="flex flex-row gap-4 pt-3">
                <div class="flex items-center">
                  <input
                    id="default-radio-1"
                    checked={selectedGender === "male"}
                    type="radio"
                    name="default-radio"
                    onChange={handleRadioChange}
                    value="male"
                    className="w-4 h-4 accent-[#037F00] bg-gray-100"
                    required
                  />
                  <div
                    for="default-radio-1"
                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Эр
                  </div>
                </div>
                <div class="flex items-center">
                  <input
                    checked={selectedGender === "female"}
                    id="default-radio-2"
                    type="radio"
                    onChange={handleRadioChange}
                    value="female"
                    name="default-radio"
                    className="w-4 h-4 accent-[#037F00] bg-gray-100"
                    required
                  />
                  <div
                    for="default-radio-2"
                    class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Эм
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div
                for="phone"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Утасны дугаар
              </div>
              <input
                type="tel"
                id="phone"
                onChange={(e) => setPhoneValue(e.target.value)}
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="9999-9999"
                maxlength="8"
                pattern="[0-9]{4}[0-9]{4}"
                required
              />
            </div>
            <div>
              <div
                for="school"
                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Харьяа байгууллага(Сургууль)
              </div>
              <input
                type="text"
                id="school"
                onChange={(e) => setOrgValue(e.target.value)}
                class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                placeholder="Монгол 3-р сургууль"
                required
              />
            </div>
          </div>
          <div class="mb-6">
            <div
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Имэйл хаяг
            </div>
            <input
              type="email"
              id="email"
              onChange={(e) => setEmailValue(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="_@mail.com"
              required
            />
          </div>
        </div>
        <div
          className={
            position === 2
              ? "max-w-md mx-auto text-center px-4 border-2 sm:px-8 py-10 rounded-xl shadow border-gray-500 dark:bg-black dark:text-neutral-400"
              : "max-w-md mx-auto text-center px-4 border-2 sm:px-8 py-10 rounded-xl shadow border-gray-500 dark:bg-black dark:text-neutral-400 hidden"
          }
        >
          <header class="mb-8">
            <h1 class="text-2xl font-bold mb-1">Имэйл баталгаажуулалт</h1>
            <p class="text-[15px] text-slate-500 dark:text-slate-300">
              Таны имэйл рүү ирсэн 4 оронтой баталгаажуулах кодыг оруулна уу
            </p>
          </header>
          <div>
            <div className="flex space-x-3 justify-center" data-hs-pin-input="">
              {Array(4)
                .fill(0)
                .map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    className="block w-[38px] text-center bg-transparent border-t-transparent border-b-2 border-x-transparent border-b-gray-200 text-sm focus:border-t-transparent focus:border-x-transparent focus:border-b-blue-500 focus:ring-0 disabled:opacity-50 disabled:pointer-events-none dark:border-b-neutral-700 dark:text-neutral-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600 dark:focus:border-b-neutral-600"
                    placeholder="⚬"
                    ref={(el) => (inputRefs.current[index] = el)}
                    maxLength="1"
                    data-hs-pin-input-item=""
                  />
                ))}
            </div>
            <div class="max-w-[260px] mx-auto mt-4">
              <button
                type="next"
                onClick={() => {
                  const pinValues = inputRefs.current.map(
                    (input) => input.value
                  );
                  if (pinValues.join("")) {
                    if (pinValues.join("") === start) {
                      setPosition(position + 1);
                      setAlert("");
                    } else {
                      setAlert("bruu)");
                    }
                  } else {
                    setAlert("yum alga hoo");
                  }
                }}
                class="w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-[#037F00] px-3.5 py-2.5 text-sm font-medium text-white shadow-sm shadow-indigo-950/10 hover:bg-[#30982e]"
              >
                Бүртгэлийг баталгаажуулах
              </button>
            </div>
          </div>
          <div class="text-sm text-slate-500 dark:text-slate-300 mt-4">
            Код хүлээж аваагүй юу?{" "}
            <a
              class="font-medium text-[#037F00] hover:text-[#30982e]"
              href="#0"
            >
              Дахин илгээх
            </a>
          </div>
        </div>
        <div className={position === 3 ? "lg:w-[80%]" : "lg:w-[80%] hidden"}>
          <div class="mb-6">
            <div
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Нууц үг
            </div>
            <div class="max-w">
              <div class="flex mb-2">
                <div class="flex-1">
                  <input
                    type="password"
                    onChange={(e) => setPassValue(e.target.value)}
                    id="hs-strong-password-with-indicator-and-hint"
                    class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
                    placeholder="•••••••••"
                  />
                  <div
                    id="hs-strong-password"
                    data-hs-strong-password='{
            "target": "#hs-strong-password-with-indicator-and-hint",
            "hints": "#hs-strong-password-hints",
            "stripClasses": "hs-strong-password:opacity-100 hs-strong-password-accepted:bg-[#037F00] h-2 flex-auto rounded-full bg-[#98B851] opacity-50 mx-1"
          }'
                    class="flex mt-2 -mx-1"
                  ></div>
                </div>
              </div>

              <div id="hs-strong-password-hints" class="mb-3">
                <div>
                  <span class="text-sm text-gray-800 dark:text-neutral-200">
                    Түвшин:{" "}
                  </span>
                  <span
                    data-hs-strong-password-hints-weakness-text='["Хоосон", "Сул", "Дунд", "Гайгүй", "Бараг л", "Төгс"]'
                    class="text-sm font-semibold text-gray-800 dark:text-[#98B851]"
                  ></span>
                </div>

                <h4 class="my-2 text-sm font-semibold text-gray-800 dark:text-white">
                  Таны нууц үг дараах зүйлийг агуулсан байх ёстой:
                </h4>

                <ul class="space-y-1 text-sm text-gray-500 dark:text-neutral-500">
                  <li
                    data-hs-strong-password-hints-rule-text="min-length"
                    class="hs-strong-password-active:text-[#037F00] flex items-center gap-x-2"
                  >
                    <span class="hidden" data-check="">
                      <svg
                        class="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </span>
                    <span data-uncheck="">
                      <svg
                        class="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                      </svg>
                    </span>
                    Хамгийн багадаа 6 тэмдэгт байна.
                  </li>
                  <li
                    data-hs-strong-password-hints-rule-text="lowercase"
                    class="hs-strong-password-active:text-[#037F00] flex items-center gap-x-2"
                  >
                    <span class="hidden" data-check="">
                      <svg
                        class="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </span>
                    <span data-uncheck="">
                      <svg
                        class="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                      </svg>
                    </span>
                    Жижиг үсэг агуулсан байх ёстой.
                  </li>
                  <li
                    data-hs-strong-password-hints-rule-text="uppercase"
                    class="hs-strong-password-active:text-[#037F00] flex items-center gap-x-2"
                  >
                    <span class="hidden" data-check="">
                      <svg
                        class="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </span>
                    <span data-uncheck="">
                      <svg
                        class="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                      </svg>
                    </span>
                    Том үсэг агуулсан байх ёстой.
                  </li>
                  <li
                    data-hs-strong-password-hints-rule-text="numbers"
                    class="hs-strong-password-active:text-[#037F00] flex items-center gap-x-2"
                  >
                    <span class="hidden" data-check="">
                      <svg
                        class="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </span>
                    <span data-uncheck="">
                      <svg
                        class="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                      </svg>
                    </span>
                    Тоо агуулсан байх ёстой.
                  </li>
                  <li
                    data-hs-strong-password-hints-rule-text="special-characters"
                    class="hs-strong-password-active:text-[#037F00] flex items-center gap-x-2"
                  >
                    <span class="hidden" data-check="">
                      <svg
                        class="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </span>
                    <span data-uncheck="">
                      <svg
                        class="flex-shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                      </svg>
                    </span>
                    Тусгай тэмдэгт агуулсан байх ёстой.
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="mb-6">
            <div
              for="confirm_password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Нууц үгээ баталгаажуулна уу
            </div>
            <input
              type="password"
              id="confirm_password"
              onChange={(e) => setPassValue2(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="•••••••••"
              required="true"
            />
            {passValue2 !== "" ? (
              passValue !== passValue2 ? (
                <p className="text-red-600 text-sm flex justify-end w-full">
                  Нууц үгээ зөв давтан хийнэ үү.
                </p>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className={position === 4 ? "lg:w-[80%]" : "lg:w-[80%] hidden"}>
          <div className="text-lg">Аюулгүй байдлын асуултууд</div>

          <div className="mb-6 font-normal flex flex-wrap gap-1 text-wrap">
            <div>Хэрэв</div>
            <div>та</div>
            <div>нууц</div>
            <div>үгээ</div>
            <div>мартсан</div>
            бол
            <div className="font-semibold">аюулгүй</div>
            <div className="font-semibold">байдлын</div>
            <div className="font-semibold">асуултад</div>
            <div>хариулж</div>
            <div>нууц</div>
            <div>үгээ</div>
            <div>сэргээх</div>
            <div>боломжтой</div>
            болно
          </div>
          <div class="mb-6">
            <div
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Таны мөрөөдлийн сургууль?
            </div>
            <input
              type="text"
              id="answer1"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Хариулт"
              onChange={(e) => setSecurityq1(e.target.value)}
              required
            />
          </div>
          <div class="mb-6">
            <div
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Таны дуртай амьтан?
            </div>
            <input
              type="text"
              id="answer2"
              onChange={(e) => setSecurityq2(e.target.value)}
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Хариулт"
              required
            />
          </div>
          <div class="mb-6">
            <div
              for="password"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Таны дуртай өнгө?
            </div>
            <input
              type="text"
              id="answer3"
              class="bg-gray-50 border-2 border-gray-500 text-gray-900 text-sm rounded-lg block w-full p-2.5 dark:bg-black dark:placeholder-gray-400 dark:text-white"
              placeholder="Хариулт"
              onChange={(e) => setSecurityq3(e.target.value)}
              required
            />
          </div>
        </div>
        <div className={position === 5 ? "lg:w-[80%]" : "lg:w-[80%] hidden"}>
          <div className="text-lg">Та аль бүлгийн хүн бэ?</div>
          <div className="mb-6 font-normal flex gap-1">
            Таны хэрэглэлд тааруулж бид мэдээлэл түгээх бөгөөд эдгээр 3
            сонголтоос сонгон уу:
          </div>
          <ul class="grid w-full gap-6 md:grid-cols-3 mb-6 ">
            <li onClick={() => setAccountTypeValue(0)}>
              <div
                for="react-option"
                class={
                  accountTypeValue === 0
                    ? "transition-all delay-150 duration-500 ease-in-out hover:bg-[#037F00] group w-full p-5 bg-transparent hover:text-gray-200 border-2 rounded-lg cursor-pointer dark:hover:text-gray-300 border-[#037F00] text-gray-600 dark:text-gray-400"
                    : "transition-all delay-150 duration-500 ease-in-out w-full group p-5 text-gray-500 border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800"
                }
              >
                <div
                  class={
                    accountTypeValue === 0
                      ? "transition-all delay-150 duration-500 ease-in-out w-full text-lg font-semibold group-hover:text-white text-[#037F00]"
                      : "transition-all delay-150 duration-500 ease-in-out w-full text-lg font-semibold text-[#037F00] dark:text-white"
                  }
                >
                  18 ба түүнээс доош насны хүүхэд
                </div>
                <div class="w-full text-sm">
                  10 жилийн сурагч буюу дунд боловсролоо хамгаалах болно
                </div>
              </div>
            </li>
            <li onClick={() => setAccountTypeValue(1)}>
              <div
                for="react-option"
                class={
                  accountTypeValue === 1
                    ? "transition-all delay-150 duration-500 ease-in-out hover:bg-[#037F00] group w-full p-5 bg-transparent hover:text-gray-200 border-2 rounded-lg cursor-pointer dark:hover:text-gray-300 border-[#037F00] text-gray-600 dark:text-gray-400"
                    : "transition-all delay-150 duration-500 ease-in-out w-full group p-5 text-gray-500 border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800"
                }
              >
                <div
                  class={
                    accountTypeValue === 1
                      ? "transition-all delay-150 duration-500 ease-in-out w-full text-lg font-semibold group-hover:text-white text-[#037F00]"
                      : "transition-all delay-150 duration-500 ease-in-out w-full text-lg font-semibold text-[#037F00] dark:text-white"
                  }
                >
                  18 ба түүнээс дээш насны залуус
                </div>
                <div class="w-full text-sm">
                  10 жилээ амжилттай төгссөн төгсөгч, оюутан болон завсар жил
                  авсан сурагч
                </div>
              </div>
            </li>
            <li onClick={() => setAccountTypeValue(2)}>
              <div
                for="react-option"
                class={
                  accountTypeValue === 2
                    ? "transition-all delay-150 duration-500 ease-in-out hover:bg-[#037F00] group w-full p-5 bg-transparent hover:text-gray-200 border-2 rounded-lg cursor-pointer dark:hover:text-gray-300 border-[#037F00] text-gray-600 dark:text-gray-400"
                    : "transition-all delay-150 duration-500 ease-in-out w-full group p-5 text-gray-500 border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800"
                }
              >
                <div
                  class={
                    accountTypeValue === 2
                      ? "transition-all delay-150 duration-500 ease-in-out w-full text-lg font-semibold group-hover:text-white text-[#037F00]"
                      : "transition-all delay-150 duration-500 ease-in-out w-full text-lg font-semibold text-[#037F00] dark:text-white"
                  }
                >
                  Асран хамгаалагч, эцэг эх, багш
                </div>
                <div class="w-full text-sm">
                  Асран хамгаалагч, эцэг эх, багш буюу өөрийн хүүхэд, сурагч
                  нартаа зориулж мэдээлэл олж, хайх
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div
          className={
            position === 6
              ? "lg:w-[60%] mb-6 gap-2 flex flex-col"
              : "lg:w-[60%] mb-6 gap-2 flex-col hidden"
          }
        >
          <div className="text-lg">
            Хэрэглэгчийн тохиргоо
            <div className="mb-2 font-normal text-base flex gap-1 text-gray-600 dark:text-gray-400">
              Өөрт тохирох тохиргоог сонгож хэрэглээгээ сайжруулна уу:
            </div>
          </div>
          <div className="flex items-center">
            <div
              onClick={() => {
                setToggle1(!toggle1);
              }}
              class="flex justify-between w-full hover:bg-black/5 dark:hover:bg-white/5 rounded-xl p-2 px-4 items-center cursor-pointer"
            >
              <div className="w-[80%]">
                <div class="text-sm md:text-base xl:text-lg font-medium dark:text-white">
                  Цахим шуудангаар мэдээ хүлээн авах
                </div>
                <div class="w-full font-normal text-sm text-black/70 dark:text-white/70 text-justify">
                  Хамгийн сүүлийн үеийн үйл ажиллагаа, сайн дурын ажил, мэдээ,
                  гэх мэт мэдээллийг цахим шуудангаар хүлээн аваарай.
                </div>
              </div>
              <input
                type="checkbox"
                value=""
                class="sr-only peer"
                checked={toggle1}
              />
              <div class="relative w-11 h-6 bg-black/10 rounded-full peer dark:bg-white/25 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-black/25 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-white/55 peer-checked:bg-[#037F00]"></div>
            </div>
          </div>
          <div className="flex items-center">
            <div
              onClick={() => {
                setToggle2(!toggle2);
              }}
              class="flex justify-between w-full hover:bg-black/5 dark:hover:bg-white/5 rounded-xl p-2 px-4 items-center cursor-pointer"
            >
              <div className="w-[80%]">
                <div class="text-sm md:text-base xl:text-lg font-medium dark:text-white">
                  Өөрийн аккаунтыг бусдаас нуух
                </div>
                <div class="w-full font-normal text-sm text-black/70 dark:text-white/70 text-justify">
                  Өөрийн аккаунтын аюулгүй байдал, нууцлалыг хангахын тулд бусад
                  хүмүүст таны хоч нэрээс бусад мэдээллийг бусадтай хуваалцахгүй
                  байх.
                </div>
              </div>
              <input
                type="checkbox"
                value=""
                class="sr-only peer"
                checked={toggle2}
              />
              <div class="relative w-11 h-6 bg-black/10 rounded-full peer dark:bg-white/25 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-black/25 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-white/55 peer-checked:bg-[#037F00]"></div>
            </div>
          </div>
          <div className="flex items-center">
            <div
              onClick={() => {
                setMode(mode);
                if (mode) {
                  document.documentElement.classList.add("dark");
                  localStorage.setItem("dark-mode", "false");
                } else {
                  document.documentElement.classList.remove("dark");
                  localStorage.setItem("dark-mode", "true");
                }
              }}
              class="flex justify-between w-full hover:bg-black/5 dark:hover:bg-white/5 rounded-xl p-2 px-4 items-center cursor-pointer"
            >
              <div className="w-[80%]">
                <div class="text-sm md:text-base xl:text-lg font-medium dark:text-white">
                  Харанхуй горим
                </div>
                <div class="font-normal text-sm text-black/70 dark:text-white/70 text-justify">
                  Харанхуй горим нь цайвар өнгөний оронд бараан өнгөний схемийг
                  ашигладаг бөгөөд гэрэл багатай орчинд үзэгдэх байдлыг
                  сайжруулдаг.
                </div>
              </div>
              <input
                type="checkbox"
                value=""
                class="sr-only peer"
                checked={!mode}
              />
              <div class="relative w-11 h-6 bg-black/10 rounded-full peer dark:bg-white/25 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-black/25 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-white/55 peer-checked:bg-[#037F00]"></div>
            </div>
          </div>
          <div class="w-full">
            <div class="grid sm:grid-cols-2 gap-y-2 sm:gap-y-0 sm:gap-x-3 lg:gap-x-5 w-full">
              <button
                type="button"
                onClick={() => {
                  if (!mode) {
                    document.documentElement.classList.add("dark");
                    localStorage.setItem("dark-mode", "false");
                  } else {
                    document.documentElement.classList.remove("dark");
                    localStorage.setItem("dark-mode", "true");
                  }
                  setToggle1(true);
                  setToggle2(true);
                  setMode(false);
                }}
                class="py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-[#037F00] text-white hover:bg-[#299c27] disabled:opacity-50 disabled:pointer-events-none"
              >
                Бүгдийг нь зөвшөөрөх
              </button>
              <button
                onClick={() => {
                  if (!mode) {
                    document.documentElement.classList.add("dark");
                    localStorage.setItem("dark-mode", "false");
                  } else {
                    document.documentElement.classList.remove("dark");
                    localStorage.setItem("dark-mode", "true");
                  }
                  setToggle1(false);
                  setToggle2(false);
                  setMode(true);
                }}
                type="button"
                class="py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-800"
              >
                Бүгдийг нь татгалзах
              </button>
            </div>
            <div class="font-normal text-sm text-black/70 dark:text-white/70 text-justify mt-1">
              "Бүгдийг нь ..." гэх товчлууруудыг хэрэглэхдээ хоёр удаа дарах
              хэрэгтэй
            </div>
          </div>
        </div>
        <div
          className={
            position === 7
              ? "lg:w-[80%] flex flex-col items-center my-6 justify-center"
              : "lg:w-[80%] flex-col items-center my-6 justify-center hidden"
          }
        >
          <iframe
            className="2xl:w-[80%] w-full h-[500px] dark:invert"
            title="Terms of Use"
            src="https://docs.google.com/document/d/e/2PACX-1vSm3lgENIaiCa6KCw2VnUgLMdluaEwPdB9nFElIFVvyCUTsyLZPsG_m3JFDrPhFJRRVn9euabRm9UO3/pub?embedded=true"
          ></iframe>
          <div class="flex items-center mt-4 gap-2">
            <input
              id="remember"
              type="checkbox"
              value=""
              class="w-4 h-4 border accent-[#037F00] border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:ring-offset-gray-800"
              required
              checked={checked}
              onClick={() => {
                setChecked(!checked);
              }}
            />
            <div
              for="remember"
              class="text-base font-medium text-gray-900 dark:text-gray-300"
            >
              Миний бие {lastnameValue} овогтой {nameValue} нь Tmull вэбсайтыг
              зөв зохистой хэрэглэж,{" "}
              <a
                href="#"
                class="text-[#037F00] hover:underline dark:text-[#d2ff70]"
              >
                Нөхцөл / Журмыг
              </a>{" "}
              уншиж, танилцан хүлээн зөвшөөрч байна.
            </div>
          </div>
        </div>
        <div
          className={
            position < 8
              ? "lg:w-[80%] w-full sm:flex-row flex-col gap-2 flex justify-between"
              : "lg:w-[80%] w-full sm:flex-row flex-col gap-2 justify-between hidden"
          }
        >
          <button
            type="back"
            onClick={() => {
              setPosition(position - 1);
            }}
            class="text-white bg-[#037F00] hover:bg-[#1e8b1c] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
          >
            Буцах
          </button>
          <div
            type="submit"
            onClick={() => {
              if (checked) {
                const dataRetriever = async () => {
                  await axios({
                    url: `https://tmull-be.vercel.app/user`,
                    method: "POST",
                    data: {
                      username: usernameValue,
                      name: nameValue,
                      lastname: lastnameValue,
                      age: ageValue,
                      gender: selectedGender,
                      phone: phoneValue,
                      org: orgValue,
                      securityq: [
                        { 1: securityq1 },
                        { 2: securityq2 },
                        { 3: securityq3 },
                      ],
                      accountType:
                        accountTypeValue === 0
                          ? "child"
                          : accountTypeValue === 1
                          ? "young adult"
                          : accountTypeValue === 2
                          ? "caregivers"
                          : "",
                      settings: [
                        { emailnotif: toggle1 },
                        { privacy: toggle2 },
                        {
                          darkmode: localStorage.getItem("dark-mode") === true,
                        },
                      ],
                      password: passValue,
                      email: emailValue,
                    },
                  }).then((response) => {
                    console.log(response?.data);
                  });
                };
                dataRetriever();
                setPosition(position + 1);
              }
            }}
            className={
              position === 7
                ? "text-white bg-[#037F00] hover:bg-[#1e8b1c] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center cursor-pointer"
                : "text-white bg-[#037F00] hover:bg-[#1e8b1c] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hidden"
            }
          >
            Дуусгах
          </div>
          <button
            type="submit"
            onClick={() => {
              if (position === 1) {
                if (
                  (selectedGender,
                  nameValue,
                  ageValue,
                  usernameValue,
                  lastnameValue,
                  phoneValue,
                  orgValue) === ""
                ) {
                } else {
                  setLoad(true);
                  if (emailValue !== "") {
                    const dataRetriever = async () => {
                      await axios({
                        url: `https://tmull-be.vercel.app/ver`,
                        method: "POST",
                        data: {
                          email: emailValue,
                        },
                      }).then((response) => {
                        if (
                          response?.data?.error !==
                          "Энэ имэйл бүртгэлтэй байна."
                        ) {
                          setStart(response?.data?.verificationCode);
                          setPosition(position + 1);
                          setTimeout(() => setLoad(false), 1000);
                        } else {
                          setAlert("Энэ имэйл бүртгэлтэй байна.");
                          setTimeout(() => setLoad(false), 1000);
                        }
                      });
                    };
                    dataRetriever();
                  }
                }
              } else if (position === 3) {
                passValue === passValue2
                  ? setPosition(position + 1)
                  : setAlert("oor bna hoo");
              } else if (position === 4) {
                if ((securityq1, securityq2, securityq3 !== "")) {
                  setPosition(position + 1);
                  setAlert("");
                } else {
                  setAlert("neg yum ni hooson bna hoo");
                }
              } else if (position === 5) {
                if (accountTypeValue === 0 || 1 || 2) {
                  setPosition(position + 1);
                  setAlert("");
                } else {
                  setAlert("neg yum ni hooson bna hoo");
                }
              } else if (position === 6) {
                setPosition(position + 1);
              }
              // if (position !== 7) {
              // 	setPosition(position + 1);
              // } else {
              // 	return 0;
              // }
            }}
            className={
              position === 7 || position === 2
                ? "text-white bg-[#037F00] hover:bg-[#1e8b1c] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center  hidden"
                : "text-white bg-[#037F00] hover:bg-[#1e8b1c] font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            }
            disabled={load}
          >
            {" "}
            {load ? (
              <div className="flex">
                <svg
                  class="animate-spin h-5 w-5 mr-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_2426_2968)">
                    <mask
                      id="mask0_2426_2968"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="24"
                      height="24"
                    >
                      <path d="M24 0H0V24H24V0Z" fill="white" />
                    </mask>
                    <g mask="url(#mask0_2426_2968)">
                      <path
                        d="M20.4838 3.51472C18.8736 1.90454 16.8385 0.785743 14.6162 0.289C12.3939 -0.207741 10.0762 -0.0619192 7.93359 0.709433L8.88267 3.34554C10.525 2.75428 12.3016 2.6425 14.0051 3.02326C15.7085 3.40403 17.2684 4.26161 18.5027 5.49585L20.4838 3.51472Z"
                        fill="#D9D9D9"
                      />
                      <path
                        d="M7.93457 0.710938C5.48964 1.59131 3.39656 3.24154 1.96998 5.41354C0.543402 7.58554 -0.139575 10.162 0.0237267 12.7554C0.187028 15.3489 1.18778 17.8192 2.87554 19.7952C4.5633 21.7711 6.84685 23.1457 9.38288 23.7125C11.919 24.2792 14.5704 24.0074 16.9387 22.9379C19.307 21.8685 21.2642 20.0591 22.516 17.7818C23.7678 15.5046 24.2465 12.8825 23.8802 10.3099C23.5139 7.73721 22.3224 5.35296 20.4849 3.51557L18.5038 5.4968C19.9124 6.90521 20.8257 8.73285 21.1064 10.7048C21.3872 12.6768 21.0202 14.6867 20.0607 16.4322C19.1012 18.1777 17.601 19.5647 15.7857 20.3844C13.9703 21.2042 11.9378 21.4126 9.99392 20.9781C8.05 20.5437 6.29961 19.49 5.0059 17.9755C3.7122 16.4609 2.9451 14.5673 2.81993 12.5793C2.69475 10.5914 3.21827 8.61653 4.31178 6.95164C5.40528 5.28675 7.00967 4.02181 8.88376 3.34699L7.93457 0.710938Z"
                        fill="white"
                        fill-opacity="0.3"
                      />
                    </g>
                  </g>
                  <defs>
                    <clipPath id="clip0_2426_2968">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
                Processing...
              </div>
            ) : (
              "Үргэлжлүүлэх"
            )}
          </button>
        </div>
        <div
          className={
            position === 8
              ? "h-96 text-lg lg:text-xl xl:text-2xl text-white justify-center flex items-center overflow-hidden shadow-inner rounded-lg relative w-full lg:w-[80%] transition-all ease-in-out duration-300 delay-0 transform"
              : "h-96 text-lg lg:text-xl xl:text-2xl text-white justify-center items-center overflow-hidden shadow-inner rounded-lg relative w-full lg:w-[80%] transition-all ease-in-out duration-300 delay-0 transform hidden"
          }
        >
          <div className=" bg-effect blur-3xl absolute bg-gradient-to-r from-[#1e8b1c] from-10% via-[#037F00] via-30% to-green-600 to-90%"></div>
          <div className="absolute">Та амжилттай бүртгэгдлээ</div>
          <div className="w-full h-full flex continue justify-center items-center preview blur-sm peer-hover:blur-0 absolute peer-hover:grayscale-0 transition-all ease-in-out duration-700 delay-0 transdiv"></div>
          <button
            type="submit"
            onClick={() => {
              window.location.href = "/";
            }}
            className="text-white continue z-20 bg-[#037F00] hover:bg-[#1e8b1c] absolute focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center  flex"
          >
            Үргэлжлүүлэх
          </button>
        </div>
      </form>
    </div>
  );
};
